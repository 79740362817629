import { Breadcrumbs, Grid, useMediaQuery, useTheme } from '@mui/material'
import './about.css'
import { Link } from 'react-router-dom'
import visionBanner from '../../assets/images/visionBanner.png'
import MobAboutCYC from '../../assets/MobileImages/MobAboutCYC.png'
import MobVisionBanner from '../../assets/MobileImages/MobVisionBanner.png'
import aboutCYC from '../../assets/images/aboutCYC.png'

import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Adjust the opacity as needed
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
});
const AboutUs = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div className='parent-section'>
            <Grid container className='aboutSection' style={{ height: isMobile ? '276px' : '424px' }}>
                <Grid item xs={12} className={classes.overlay}>
                    <div className='help-us-header'>
                        About Us
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                                <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                                    Home
                                </Link>
                                <Link
                                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                                    color="inherit"
                                    to={"/about"}
                                >
                                    About
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <br />
            <br />
            <div className='help-us-header' style={{ width: isMobile ? '90%' : '100%', color: '#000000', textAlign: 'left', marginLeft: isMobile ? '0' : '600px' }}>
                About CYC
                <div className='about-horizontal-line' />
            </div>
            <br />
            <div className='section-2'>
                <div className='aboutCYC' >
                    <img src={ isMobile ? MobAboutCYC : aboutCYC} height={'100%'} width={'100%'} alt='aboutCYC'/>
                </div>
                <div className='sub-section-2'>
                    <div className='about-us-desc'>
                        <br />
                        "Clean Your Closet" (CYC) Founded  by Subhaan is a remarkable example of how a simple school assignment can transform into a meaningful social impact initiative. Subhaan's journey began in grade 8 with a digital design assignment that led to the creation of a donor-recipient matching app. This app facilitates the exchange of pre-loved clothing items between donors and NGOs, ensuring that donations reach those who need them most.
                        <br /><br />
                        Over the years, CYC has pivoted & evolved into a robust platform that not only simplifies the donation process but also ensures that the transfer of goods is meaningful and respectful. The initiative's doorstep pickup service in Mumbai and nearby areas, along with regular collection camps in buildings, demonstrates a commitment to convenience and community engagement.
                        <br /><br />
                        The support from a growing community of donors, partnerships with NGOs like Goonj, Prayaas, and Bittiya Foundation, and collaborations with corporate offices are testament to CYC's effectiveness and impact. With over 15,000 items collected from more than 1,000 donors, CYC has made a significant difference in the lives of many.
                        <br /><br />
                        Subhaan's vision to expand CYC to other cities and engage more NGOs and corporates shows a forward-thinking approach to scaling the impact. Corporate participation in organizing donation camps and sponsoring deliveries is a powerful way to broaden the initiative's reach and influence.
                        <br /><br />
                        CYC is a shining example of how youth-driven initiatives can lead to substantial social change, demonstrating the power of community, compassion, and innovation in addressing societal needs.

                    </div>
                </div>
            </div>
            <br />
            <div className='abouts-section-3'>
                <div className='our-mission-container'>
                    <div className='about-us-header'>
                        Our
                        <span className="main-text"> Mission </span>
                    </div>
                    {isMobile ? <div className='about-horizontal-line' /> : <div className='thin-horizontal-line' />}
                    {/* <br/> */}
                    <div className='our-mission'>
                        Our mission at CYC is to simplify the process of donating clothing, directly connecting donors with NGOs to ensure that every item reaches someone in need. We aim to expand our service across cities, encouraging more individuals and corporations to participate, thereby increasing our impact on reducing clothing waste and supporting community well-being.
                    </div>
                </div>
            </div>
            {isMobile && 
            <div style={{width:'85%',marginBottom:'30px'}}>
                <div className='about-us-header'>
                    Vision
                <div className='about-horizontal-line' />
            </div>
            </div>}
            <div className='section-4'>
                <img src={ isMobile ? MobVisionBanner : visionBanner} alt='visionBanner' style={{ height: isMobile ? '208px' : '630px', width: isMobile ? '350px' : '500px' }} />
                {/* <div className='visionBanner'/> */}
                <div className='section-4-content'>
                    {!isMobile && <div className='about-us-header'>
                        Vision
                        <div className='about-horizontal-line' />
                    </div>}
                    <div className='vision-section'>
                        Creating a world where every piece of clothing gets a second chance.
                        <br /><br />
                        Clean Your Closet (CYC) envisions a world where every piece of pre-loved clothing is a catalyst for positive change, fostering a culture of sustainability and compassion. Our goal is to expand nationwide, creating a robust network of donors, NGOs, and corporates, all united in transforming surplus into support and fostering community empowerment. Through innovation and collaboration, we aim to redefine the act of giving, ensuring every donation not only reaches those in need but also promotes a cycle of kindness and environmental stewardship. With CYC, we're weaving a future where every garment amplifies impact, nurturing a more connected and conscientious society.
                        <br /><br />
                    </div>
                </div>
            </div>
            <div className='abouts-section-3'>
                <div className='our-mission-container'>

                    <div className='about-us-header'>
                        <span >Our</span>
                        <span className="main-text"> Purpose </span>
                    </div>
                    {isMobile ? <div className='about-horizontal-line' /> : <div className='thin-horizontal-line' />}
                    <div className='our-mission'>
                        CYC aims to streamline clothing donations, connecting donors with NGOs to aid those in need, reduce waste, and promote sustainability and empathy within communities. Our objective is to expand our reach and increase impactful partnerships.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs