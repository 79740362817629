import { Box, Button, FormControl, Grid, IconButton, MenuItem, Modal, Select, Slide, TextField } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleAdminFormModal } from '../../redux'
import * as Yup from 'yup'
import { ErrorMessage, Form, Formik } from 'formik'
import CloseIcon from '@mui/icons-material/Close'
import { isEmpty, isString } from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { primaryColor } from '../../Styles/styles'
import axios from 'axios'

const AddCardsForm = (params: any) => {

    const store = useSelector((state: any) => state.handleAdminFormModal)
    const dispatch = useDispatch()

    const formRef = useRef<any>(null)


    const Formbody = () => {

        const columns = params.columns
        const columnsToHide = ["id", "_id", "createdAt", "updatedAt"]
        const finalColumns = columns.filter((key: any) => !columnsToHide.includes(key.field))
        const initialValues: any = {}
        const validationSchema: any = {}
        const [imageLink, setImageLink] = useState(params.selectedRow.id[0]?.['image'])
        const fileInputRef = useRef<any>(null);
        const { id =null , _id =null,__v=null, ...initialData } = params?.selectedRow?.id[0] || {}
        // const initialData = params.selectedRow.id[0]

        finalColumns.forEach((column: any) => {
            const value = params.selectedRow.id[0]?.[column.field];
            initialValues[column.field] = (value !== undefined && value !== null) ? value : '';
            if (column.field !== 'image') {
                validationSchema[column.field] = Yup.string().required(`${column.field} is required`);
            }
        });
        const formikValidationSchema = Yup.object().shape(validationSchema);

        const uploadData = async (values: any) => {
            try {

                const formData: any = new FormData()
                formData.append('file', imageLink)
                formData.append('cardData', JSON.stringify(values))
                await axios.post(`https://cricketspin.rewardzpromo.com/api/add-partner-data?folderName=partners`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                setImageLink('')
            } catch (error) {
                console.log("🚀 ~ uploadData ~ error:", error)
            }
        }

        async function uploadFileToS3(imageLink: any) {
            try {
                const formData: any = new FormData();

                formData.append('file', imageLink);
                const response = await axios.post(`https://cricketspin.rewardzpromo.com/api/update-image-for-partner?folderName=partners`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                console.log('File uploaded successfully:', response.data);
                return response.data
            } catch (error) {
                console.log("🚀 ~ uploadFileToS3 ~ error:", error)

            }
        }

        const updateProduct = async (values: Object) => {
            try {
                const response = await axios.post('https://cricketspin.rewardzpromo.com/api/update-partner-data', values)
                console.log("🚀 ~ updateProduct ~ response:", response.data)
            } catch (error) {
                console.log("🚀 ~ updateProduct ~ error:", error)

            }
        }

        const handleSubmit = async (values: any) => {
            try {
                if(JSON.stringify(initialData) === JSON.stringify(formRef.current.values)){
                    return
                }
                if (!imageLink) {
                    formRef.current.setFieldError('image', 'Image is required')
                    return
                }
                if (params.selectedRow.type === 'edit') {
    
                    values._id = params.selectedRow.id[0]?.["_id"]
                    if ((imageLink?.type === 'image/jpeg') || (initialData.image !== imageLink && !imageLink?.includes('amazonaws.com/') && !isEmpty(imageLink))) {
                        console.log('New image pushed to server')
                        const response = await uploadFileToS3(imageLink)
                        console.log("🚀 ~ handleSubmit ~ response:", response)
                        values.image = response.data
    
                    } else {
                        console.log('No changes in image or entered any manual link')
                    }
                    await updateProduct(values)
    
                } else {
                    await uploadData(values)
                }
                params.handleUnselectAll()
                params.fetchData()
                dispatch(handleAdminFormModal(false))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)
                alert('Failed to add. Please try again!')
            }
        }
        const handleCancel = async () => {
            try {
                await formRef.current.setFieldValue('image', '')
                setImageLink("")
            } catch (error) {
                console.log("🚀 ~ handleCancel ~ error:", error)

            }
        }
        const handleFileChange = async (event: any) => {

            const file = event.target.files[0];
            setImageLink(file)
            formRef.current.setFieldValue('image', '')

        };
        const handleIconClick = () => {
            fileInputRef.current.click();
        };
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={formikValidationSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ width: '842px', overflow: 'hidden', height: '100%' }}>
                            <div style={{ width: '100%', marginTop: '2%', display: 'flex', justifyContent: 'space-between' }}>
                                <div className='form-footer-header'>
                                    {params.selectedRow.type === 'edit' ? "Edit details" : "Add details"}
                                    <CloseIcon onClick={() => dispatch(handleAdminFormModal(false))} sx={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            {finalColumns.map((data: any, index: any) => (
                                <React.Fragment key={index}>
                                    {
                                        data.field === 'type' ?
                                            <Grid item xs={12} md={6}>
                                                <div className='form-content'>
                                                    Category
                                                </div>
                                                <FormControl fullWidth error={Boolean(formik.touched.category && formik.errors.category)}>
                                                    <Select
                                                        className='form-textfield'
                                                        sx={{
                                                            marginTop: '2%',
                                                            width: '370px',
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                            '& .MuiFormHelperText-root': { color: 'red' },
                                                        }}
                                                        id={data.field}
                                                        name={data.field}
                                                        value={formik.values[data.field]}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                            console.log('Selected value:', e.target.value);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <MenuItem key={1} value={'card'}>card</MenuItem>
                                                        <MenuItem key={2} value={'logo'}>logo</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid> :
                                            data.field === "image" ?
                                                <>
                                                    {
                                                        imageLink ?
                                                            <Grid item xs={12} md={6}>
                                                                <div className='form-content'>
                                                                    Image Selected:
                                                                </div>
                                                                <img src={isString(imageLink) ? imageLink : URL.createObjectURL(imageLink)} alt="Selected" style={{ height: '100px', width: '100px', marginTop: '2%' }} />
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={handleCancel}
                                                                    sx={{ marginLeft: '30%' }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12} md={6} >
                                                                <div className='form-content'>
                                                                    Image
                                                                </div>
                                                                <TextField
                                                                    placeholder="Enter or paste image link"
                                                                    // className='form-textfield'
                                                                    sx={{
                                                                        marginTop: '2%',
                                                                        width: '340px',
                                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' },
                                                                        '& .MuiFormHelperText-root': { color: 'red' }
                                                                    }}
                                                                    id={data.field}
                                                                    name={data.field}
                                                                    value={formik.values.id}
                                                                    onChange={formik.handleChange}
                                                                    // onChange={handleFileChange}
                                                                    helperText={<ErrorMessage name="image" />}
                                                                />
                                                                <input
                                                                    accept="image/*"
                                                                    type="file"
                                                                    style={{ display: 'none' }}
                                                                    ref={fileInputRef}
                                                                    onChange={handleFileChange}
                                                                />
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={handleIconClick}
                                                                    sx={{ marginTop: '2%' }}
                                                                >
                                                                    <CloudUploadIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={handleCancel}
                                                                    sx={{ marginTop: '2%' }}
                                                                >
                                                                    {/* <CloudUploadIcon /> */}
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                    }
                                                </>
                                                :
                                                <Grid item xs={12} md={6}>
                                                    <div className='form-content'>
                                                        {data.headerName}
                                                    </div>
                                                    <TextField placeholder={data.field === "section_id" ? "2, 4 or 5" : ""} className='form-textfield' sx={{ marginTop: '2%', width: '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#57BC6E' }, '& .MuiFormHelperText-root': { color: 'red' } }} id={data.field} name={data.field} value={formik.values[data.field]} onChange={formik.handleChange} helperText={<ErrorMessage name={data.field} />}
                                                    />
                                                </Grid>
                                    }
                                </React.Fragment>
                            ))}
                        </Grid>
                        {/* <Grid container sx={{ justifyContent: 'center', marginTop: '25px' }}> */}
                        <Button disabled={ JSON.stringify(initialData) === JSON.stringify(formRef.current?.values) ? true : false } color="primary" variant="contained" type="submit" sx={{ margin: '5%', marginLeft: '35%', height: '55px', width: '187px', fontSize: '16px', backgroundColor: primaryColor, ":hover": { backgroundColor: primaryColor } }}>
                            Submit
                        </Button>
                        {/* </Grid> */}
                        <br />
                    </Form>
                )}
            </Formik>
        )
    }
    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleAdminFormModal(false))}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{
                        backgroundColor: '#ffffff', borderRadius: '8px', maxHeight: '70%', minHeight: '50%', width: '55%', marginLeft: '18%', marginTop: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll', '&::-webkit-scrollbar': { display: 'none' },
                        '-ms-overflow-style': 'none',  /* IE and Edge */
                        'scrollbar-width': 'none'
                    }}>
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default AddCardsForm
