import './partner.css';
import { Box, Button, Card, CardActions, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material';
import { primaryColor } from '../../Styles/styles';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Partner = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [partnerData, setpartnerData] = useState([])

    const SimpleSlider = () => {
        
        const settings = {
            infinite: true,
            speed: 3000,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: 'linear',
            variableWidth: true,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <div style={{ maxWidth: '100%', overflow: 'hidden', display: 'flex',alignItems: 'center', height: '100vh'}}>
                <Slider {...settings}>
                    {partnerData.map((value: any, index: number) => value.type === "logo" &&

                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: 'auto',
                                maxHeight: '98px',
                                marginLeft: '100px',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={value.image}
                                alt={value.name}
                                style={{
                                    maxWidth: '170px',
                                    maxHeight: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    )
                    }
                </Slider>
            </div>
        );
    };

    const fetchPartnerData = async() => {
        try {
            const response = await axios.get('https://cricketspin.rewardzpromo.com/api/get-partner-data')
            setpartnerData(response.data.data)
        } catch (error) {
            console.log("🚀 ~ fetchPartnerData ~ error:", error)
        }
    }

    useEffect(()=>{
        if(partnerData.length === 0){
            fetchPartnerData()
        }
    },[partnerData])

    return (
        <>
            <div className='parent-section'>
                <div className='section-1'>
                    <div className="about-overlay">

                        <div className='partner-header'>
                            Partners
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                                    <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                                        Home
                                    </Link>
                                    <Link
                                        // underline="hover"
                                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                                        color="inherit"
                                        to={"/partner"}
                                    >
                                        Partners
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="centered-div">
                    <div className='card-container'>
                        { partnerData && partnerData.map((value: any) => value.type === "card" &&
                            (
                                <Card className='card' sx={{
                                    height: isMobile ? '468px' : '540px', backgroundColor: 'inherit', boxShadow: isMobile ? 'none' : '', marginTop: '10%'
                                }} key={value.name} >
                                <img
                                    src={value.image}
                                    alt={value.name}
                                    style={{
                                        width: '100%',
                                        // height: '245px',
                                        maxWidth: isMobile ? '224px' : '370px',
                                        maxHeight: isMobile ? '148px' : '245px',
                                        minHeight: isMobile ? '148px' : '245px',
                                        objectFit: 'contain',
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                /><br /><br />
                                <CardContent sx={{
                                    bgcolor: '#f1f1f1',
                                    // minWidth: '100%',
                                    // maxHeight: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                    <Typography sx={{ fontFamily: 'Lora', fontSize: '21px', fontWeight: '700', lineHeight: '26.88px', textAlign: 'left', margin: '10px' }}>
                                        {value.name}
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Roboto', fontSize: isMobile ? '14px' : '16px', fontWeight: '400', lineHeight: '24px', textAlign: 'left', color: '#666666', marginTop: '20px', margin: '10px' }}>
                                        {value.desc.length <= 180 ? value.desc : (value.desc.substr(0, 180) + "...")}
                                    </Typography><br />
                                    <CardActions>
                                        <Link to={{ pathname: "/singlepartner" }} state={{ name: value.name }}>
                                            <Button variant='contained' className='card-button' sx={{ backgroundColor: primaryColor, color: '#FFFFFF', borderColor: primaryColor, ":hover": { backgroundColor: '#FF7000', borderColor: '#FF7000', borderWidth: 1 }, margin: '10px' }}>READ MORE</Button>
                                        </Link>
                                    </CardActions>
                                </CardContent>

                            </Card>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='section-3'>
                <div style={{ margin: isMobile ? '5%': 0, textAlign: 'center', marginTop: 15 }}>
                    <div className='help-us-header'>
                        <span >Corporate</span>
                        <span className="main-text"> Partners </span>
                    </div>
                    <br />
                    <div className='our-mission'>
                        Our corporate partners are committed to driving positive social impact
                    </div>
                </div>
                <SimpleSlider />
            </div>
        </>
    )
}

export default Partner