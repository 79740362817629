import ContactBar from "./components/ContactBar/ContactBar";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
// import SocialSharingBar from "./components/SocialSharingBar/SocialSharingBar";
import About from "./Views/About/About";
import AboutOwner from "./Views/About/AboutOwner";
import ContactUs from "./Views/ContactUs/ContactUs";
import ChangeChampions from "./Views/ChangeChampions/ChangeChampions";
import GetInvolved from "./Views/GetInvolved/GetInvolved";
import Home from "./Views/Home/Home";
import Gallery from "./Views/Gallery/Gallery";
import Partner from "./Views/Partners/Partner";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import DonorSignupForm from "./components/DonorSignupForm/DonorSignupForm";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { closeAllModals } from "./redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoinUsForm from "./components/JoinUsForm/JoinUsForm";
import CorporatePartnerForm from "./components/CorporatePartnerForm/CorporatePartnerForm";
import NgoPartnerForm from "./components/NgoPartnerForm/NgoPartnerForm";
import HousingSocietyForm from "./components/HousingSocietyForm/HousingSocietyForm";
import ThankYouModal from "./components/ThankYouModal/ThankYouModal";
import PartnerInDetail from "./Views/Partners/PartnerInDetail";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ScrollToTop from "./scrollToTop";
import { AnimatePresence, motion } from 'framer-motion';
import AdminLogin from "./Views/Admin/AdminLogin";
import AdminHome from "./Views/Admin/AdminHome";
import SideBar from "./Views/Admin/SideBar";
import AdminPartner from "./Views/Admin/AdminPartner";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const SignupModal: any = useSelector((store: any) => store.handleSignupModal);
  const JoinusModal: any = useSelector((store: any) => store.handleJoinUsModal);
  const handleCorporateParterModal: any = useSelector((store: any) => store.handleCorporateParterModal);
  const handleNgoPartnerModal: any = useSelector((store: any) => store.handleNgoPartnerModal);
  const handleHousingSocietyModal: any = useSelector((store: any) => store.handleHousingSocietyModal);
  const handleThankYouModal: any = useSelector((store: any) => store.handleThankYouModal);
  const dispatch = useDispatch()
  const adminToken = localStorage.getItem('adminToken')
  const PageWrapper = ({ children }: any) => {
    const location = useLocation()
    const pageVariants = {
      initial: {
        opacity: 0,
        y: -50
      },
      in: {
        opacity: 1,
        y: 0
      },
      out: {
        opacity: 0,
        y: 50
      }
    };
  
    const pageTransition = {
      type: "tween",
      ease: "anticipate",
      duration: 0.5
    };
  
    return (
      <motion.div
      key={location.key}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        {children}
      </motion.div>
    );
  };

  useEffect(() => {
    const handlePageReload = () => {
      dispatch(closeAllModals())
    };

    window.addEventListener('beforeunload', handlePageReload);

    return () => {
      window.removeEventListener('beforeunload', handlePageReload);
    };
  }, [dispatch]);

  const Main = () => {
    const location = useLocation()

    return (
      <Box sx={{ display: 'flex', flexDirection: location.pathname.startsWith('/admin') ? 'row': 'column' }}>
        {/* <SocialSharingBar /> */}
        <ScrollToTop />
        {
          location.pathname.startsWith('/admin') && location.pathname !== '/adminLogin' && <SideBar />
        }
        { !location.pathname.startsWith('/admin') && <ContactBar /> }
        { !location.pathname.startsWith('/admin') && !isMobile && <Navbar />}
        <Routes>
          <Route path="/" element={<PageWrapper><Home /></PageWrapper>} />
          <Route path="/about" element={<PageWrapper><About /></PageWrapper>} />
          <Route path="/aboutowner" element={<PageWrapper><AboutOwner /></PageWrapper>} />
          <Route path="/pages" element={<PageWrapper><Gallery /></PageWrapper>} />
          <Route path="/changechampions" element={<PageWrapper><ChangeChampions /></PageWrapper>} />
          <Route path="/partner" element={<PageWrapper><Partner /></PageWrapper>} />
          <Route path="/singlepartner" element={<PageWrapper><PartnerInDetail /></PageWrapper>} />
          <Route path="/contactus" element={<PageWrapper><ContactUs /></PageWrapper>} />
          <Route path="/getInvolve" element={<PageWrapper><GetInvolved /></PageWrapper>} />
          <Route path="/adminLogin" element={<PageWrapper><AdminLogin /></PageWrapper>} />
          {adminToken &&
            <>
              <Route path="/adminHome" element={<PageWrapper><AdminHome /></PageWrapper>} />
              <Route path="/adminPartner" element={<PageWrapper><AdminPartner /></PageWrapper>} />
            </>
          }
        </Routes>
        { !location.pathname.startsWith('/admin') && <Footer /> }
      </Box>
    )
  }
  return (
    <AnimatePresence >
      <Router>
        <Main />
        <ToastContainer position="bottom-center" />

        {SignupModal && <DonorSignupForm />}
        {JoinusModal && <JoinUsForm />}
        {handleCorporateParterModal && <CorporatePartnerForm />}
        {handleNgoPartnerModal && <NgoPartnerForm />}
        {handleHousingSocietyModal && <HousingSocietyForm />}
        {handleThankYouModal && <ThankYouModal />}
      </Router>
    </AnimatePresence>

  );
}

export default App;
