import Box from '@mui/material/Box';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DeselectOutlinedIcon from '@mui/icons-material/DeselectOutlined';

const AdminHome = () => {

  const [Images, setImages] = useState<any>([])
  const [newImages, setnewImages] = useState<any>([])
  const [imagesToDelete, setImagesToDelete] = useState<any>([]);

  const fetchImages =async () => {
    try {
      
      const response = await axios.get('https://cricketspin.rewardzpromo.com/api/fetch-images')
      setImages(response.data.data)

    } catch (error) {
      console.log("🚀 ~ fetchImages ~ error:", error)
      
    }
  }

  const handleAddImage = (event: any) => {
    try {
        const newImages = Array.from(event.target.files).map((file: any) => file);
        setnewImages((Images: any) => [...Images, ...newImages]);
    } catch (error) {
      console.log("🚀 ~ handleAddImage ~ error:", error)
    }
  };

  // Mark image for deletion
  const handleDeleteImage = (imageURL: any) => {
    setImagesToDelete([...imagesToDelete, imageURL]);
    setImages((prevImages: any[]) =>
      prevImages.map((image: any) =>
        image === imageURL
          ? { ...image, disabled: true }
          : image
      )
    );
  };

  const deleteNewlyAddedImage = (imageUrl: any) => {
    setnewImages((prevImages: any) => prevImages.filter((url: string) => url !== imageUrl));
  }
  // Save changes to API
  const handleSaveChanges = async () => {
    try {
      
      if(newImages.length ===0 && deleteNewlyAddedImage.length === 0){
        return
      }
      const formData = new FormData();
      
      // Append each selected file to the FormData object
      
      newImages.forEach((file: any) => {
        formData.append('file', file);
      });
      formData.append('imagesToDelete',JSON.stringify(imagesToDelete))

      const response = await axios.post('https://cricketspin.rewardzpromo.com/api/upload-gallery', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log('response.data', response.data)
      setnewImages([])
      setImagesToDelete([])
      fetchImages()
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const deSelectAll = () => {
    setImages((prevImages: any[])=> prevImages.map((value: any)=>({...value, disabled: false})))
    setImagesToDelete([])
  }


  useEffect(()=>{
    if(Images.length===0){
      fetchImages()
    }
  },[Images])

  return (

      <Box sx={{  overflowY: 'scroll', width: '200vh', height: '100vh'}}>
        <Typography variant="h4" gutterBottom sx={{ padding: '2rem' }}>
          Manage Images
        </Typography>

        {/* Image Grid */}
        <Grid container spacing={2} p={2} pl={3}>
          {Images && Images.map((imageURL: any, index: any) => (
            <Grid item key={index} xs={6} sm={4} md={2} >
              <Box sx={{
                position: 'relative', width: '50%', aspectRatio: '1/1',  // Red border when disabled
                opacity: imageURL.disabled ? 0.6 : 1, // Dim the box if disabled
                pointerEvents: imageURL.disabled ? 'none' : 'auto',
              }}  > {/* Maintain a square aspect ratio */}
                <img
                  src={imageURL.link}
                  alt={`${index}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensure the image covers the entire box
                    borderRadius: '8px'
                  }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                  onClick={() => handleDeleteImage(imageURL)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
          {newImages.length > 0 && newImages.map((imageURL: any, index: any) => (
            <Grid item key={index} xs={6} sm={4} md={2} >
              <Box sx={{
                position: 'relative', width: '50%', aspectRatio: '1/1',  // Red border when disabled
                opacity: imageURL.disabled ? 0.6 : 1, // Dim the box if disabled
                pointerEvents: imageURL.disabled ? 'none' : 'auto',
              }}  > {/* Maintain a square aspect ratio */}
                <img
                  src={URL.createObjectURL(imageURL)}
                  alt={`${index}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensure the image covers the entire box
                    borderRadius: '8px'
                  }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                  onClick={() => deleteNewlyAddedImage(imageURL)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}

        </Grid>

        {/* Add New Image */}
        <Box sx={{ margin: '2rem' }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            multiple
            onChange={handleAddImage}
          />
          <label htmlFor="file-upload">
            <IconButton component="span">
              <AddIcon />
            </IconButton>
          </label>
            <IconButton onClick={deSelectAll} component="span">
              <DeselectOutlinedIcon />
            </IconButton>
        </Box>

        {/* Save Button */}
        { (newImages.length > 0 || imagesToDelete.length > 0) &&  <Box sx={{ marginTop: '2rem' }}>
          <Button variant="contained" color="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Box>}
      </Box>

  );
};


export default AdminHome