import { Box, Button, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Slide, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../DonorSignupForm/donorsignupform.css';

import axios from 'axios';
import { primaryColor } from '../../Styles/styles';
import { useDispatch } from 'react-redux';
import { handleNgoPartnerModal, handleThankYouModal } from '../../redux';
import { useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import close from '../../assets/svg/close.svg';

const NgoPartnerForm = () => {
    const store: any = useSelector((store: any) => store.handleNgoPartnerModal);
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Formbody = () => {
        const initialValues = {
            name: '',
            phoneNumber: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            state: '',
            city: '',
            zipcode: '',
            industry: '',
            personName: '',
            role: '',
            ngoRegion: '',
            receiveItems: '',
            recyclingItems: '',
            notes: '',
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Full Name is required'),
            industry: Yup.string().max(50, 'Industry name must be at most 100 characters').required('This field is required'),
            personName: Yup.string().required('Person Name is required'),
            role: Yup.string().required('Role is required'),
            email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Invalid email'
                )
                .required('Email is required'),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number format').required('Phone Number is required'),
            addressLine1: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            addressLine2: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            city: Yup.string().max(50, 'City name must be at most 100 characters').required('City is required'),
            state: Yup.string().max(50, 'State name must be at most 50 characters').required('State is required'),
            zipcode: Yup.string().length(6, 'Zip code must be of 6 characters').matches(/^\d+$/, 'Zip code must contain only numbers').required('Zip code is required'),
            ngoRegion: Yup.string().max(100, 'Max 100 characters').required('This field is required'),
            receiveItems: Yup.string().max(100, 'Max 100 characters').required('This field is required'),
            recyclingItems: Yup.string().required('Please select an option'),
            // Add validation for other fields here
        });

        const handleSubmit = async (values: any) => {
            try {
                const formdata = {
                    "entry.1794283421": values.name,
                    "entry.1226858709": values.industry,
                    "entry.10174237": values.personName,
                    "entry.2111074751": values.role,
                    "entry.1573129547": values.email,
                    "entry.746581489": values.phoneNumber,
                    "entry.1002605515": values.addressLine1,
                    "entry.930481741": values.addressLine2,
                    "entry.701928256": values.city,
                    "entry.90578577": values.state,
                    "entry.755849992": values.zipcode,
                    "entry.963055206": values.ngoRegion,
                    "entry.1750884732": values.receiveItems,
                    "entry.1138309526": values.recyclingItems,
                    "entry.1367600464": values.notes,
                }

                await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/NgoPartnerSignup`, formdata)
                dispatch(handleNgoPartnerModal(false))
                dispatch(handleThankYouModal({ state: true, formName: 'ngoPartner' }))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)
            }
        }
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (

                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container className='form-donate-now' sx={{ width: isMobile ? '100%' : '942px' }}>
                            <div style={{ width: '100%' }}>
                                <div className='form-footer-header'>
                                    NGO Signup Form
                                    <img src={close} alt="logo" onClick={handleClose} />
                                </div>
                                <div className='form-horizontal-line' />

                                <div className='form-message'>We are delighted that your organisation is interested in partnering with CYC to support and enhance the impact of our clothing donation initiative. Your expertise and network are invaluable in ensuring that donations reach those who need them most. Please provide the following information to help us understand how we can collaborate effectively.</div>
                            </div>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    NGO Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="name" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Area of Focus/Expertise
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="industry"
                                    name="industry"
                                    value={formik.values.industry}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="industry" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Contact Person Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="personName"
                                    name="personName"
                                    value={formik.values.personName}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="personName" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Email Address
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="email" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Phone Number
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="phoneNumber" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Position/Role in the NGO
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="role"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="role" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    NGO Address Line 1
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine1" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    NGO Address Line 2
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine2"
                                    name="addressLine2"
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine2" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    State
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="state" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    City
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="city"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="city" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Zip Code
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="zipcode"
                                    name="zipcode"
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="zipcode" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Regions/Cities Your NGO Operates in:
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="ngoRegion"
                                    name="ngoRegion"
                                    value={formik.values.ngoRegion}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="ngoRegion" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                    Types of Clothing items your NGO is interested in receiveing
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="receiveItems"
                                    name="receiveItems"
                                    value={formik.values.receiveItems}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="receiveItems" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                    Is your NGO able to assist in repurposing or recycling clothing items ?
                                </div>
                                <FormControl fullWidth error={Boolean(formik.touched.recyclingItems && formik.errors.recyclingItems)}>

                                    <RadioGroup
                                        id="recyclingItems"
                                        name="recyclingItems"
                                        value={formik.values.recyclingItems}
                                        onChange={formik.handleChange}
                                        row

                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    {formik.touched.recyclingItems && formik.errors.recyclingItems ? (
                                        <Typography style={{ fontSize: '12px', margin: 2 }} sx={{ color: 'red' }}>
                                            {formik.errors.recyclingItems}
                                        </Typography>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='form-content'>
                                    Additional Notes
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ height: '170px', marginBottom: isMobile? '30%' : '10%', marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="notes"
                                    name="notes"
                                    multiline
                                    rows={6}
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="notes" />}
                                    fullWidth
                                />
                                <div className='box-button'>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            height: '55px',
                                            width: '187px',
                                            fontSize: '16px',
                                            marginLeft: isMobile ? '100px' : '300px',
                                            backgroundColor: primaryColor,
                                            ":hover": { backgroundColor: primaryColor },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };

    const handleClose = () => {
        dispatch(handleNgoPartnerModal(false))
    };


    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleNgoPartnerModal(false))}
            style={{ marginTop: isMobile ? '20%' : '5vh' }}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{
                        borderRadius: '8px',
                    }}
                >
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default NgoPartnerForm