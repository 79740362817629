import { Button, Container, Typography, Grid, Box } from '@mui/material';
// import ngoo from '../../assets/svg/ngoo.svg';
import ngoo from '../../assets/svg/Clean Your Closet(white).png';
import map from '../../assets/svg/map.svg';

import './footer.css';
import { handleCorporateParterModal } from '../../redux/index';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { primaryColor } from '../../Styles/styles';

const Footer = () => {
    const dispatch = useDispatch()
    const location = useLocation();

    return (
        <Box>
            {/* Footer header */}
            {location.pathname === '/' &&
                <div className='footerBar'>
                    Corporate partners - Join hands with us now for a better future, where every donation counts and makes a difference.
                    <Button onClick={() => dispatch(handleCorporateParterModal(true))} variant="contained" className='footer-button' sx={{ backgroundColor: '#333333', ":hover": { backgroundColor: '#333333' }, margin: '10px' }}>Join Now</Button>
                </div>
            }

            {/* Footer */}
            <Box sx={{ backgroundColor: '#222222', color: '#FFFFFF', padding: '50px 0', backgroundImage: `url(${map})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', position: 'relative', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                <Container maxWidth="lg">
                    <Grid sx={{display:'flex',justifyContent:'space-around'}} container>
                        <Grid item xs={12} sm={6} md={3}>
                            <div className='footerDesc'>
                                <img src={ngoo}  alt='about-us'  style={{ marginBottom: '10%'}}/>
                                <Typography>
                                Where Generosity Meets Impact. Together, We're Building Brighter Futures Through Clothing Donation. Transform Lives, One Garment at a Time.
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <div className='footerDesc'>
                                <div className='footer-Header'>WHO WE ARE</div>
                                <div className='footer-horizontal-line' />
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/' style={{ textDecoration:'none', marginLeft: '10px', color: location.pathname ==='/' ? primaryColor : '#FFFFFF' }}>Home</Link></div>
                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/about' style={{ textDecoration:'none', marginLeft: '10px', color: (location.pathname === '/about' || location.pathname === '/aboutowner') ? primaryColor : '#FFFFFF' }}>About</Link></div>

                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/pages' style={{ textDecoration:'none', marginLeft: '10px', color: location.pathname ==='/pages' ? primaryColor : '#FFFFFF' }}>Gallery</Link></div>

                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/changechampions' style={{ textDecoration:'none', marginLeft: '10px', color: location.pathname ==='/changechampions' ? primaryColor : '#FFFFFF' }}>Change Champions</Link></div>

                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/partner' style={{ textDecoration:'none', marginLeft: '10px', color: location.pathname ==='/partner' ? primaryColor : '#FFFFFF' }}>Partner</Link></div>

                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/getInvolve' style={{ textDecoration:'none', marginLeft: '10px', color: location.pathname ==='/getInvolve' ? primaryColor : '#FFFFFF' }}>Get Involved</Link></div>

                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'>{`> `}<Link to='/contactus' style={{ textDecoration:'none', marginLeft: '10px', color: location.pathname ==='/contactus' ? primaryColor : '#FFFFFF' }}>Contact</Link></div>

                                </div>

                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <div className='footerDesc'>
                                <div className='footer-Header'>WHERE WE WORK</div>
                                {/* <Divider className='thinHorizontalLine' /> */}
                                <div className='footer-horizontal-line' />
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'><div className='footer-phoneIcon-images'></div><div style={{ marginLeft: '10px' }}>7506681015</div></div>


                                </div>
                                <div className='screenOptions'>
                                    <div className='footerWhoWeAre'><div className='footer-mail-images'></div><div style={{ marginLeft: '10px' }}>cleanyourcloset122@gmail.com</div></div>


                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <div>
                <Typography variant="body1" align="center" style={{ padding: '20px', backgroundColor: '#333333', color: '#FFFFFF' }}>
                    Copyright 2021 © NGOO Figma Template. Designed by Rometheme.
                </Typography>
            </div>
        </Box>
    );
}

export default Footer;
