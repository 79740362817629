import './changechampions.css'
import { primaryColor } from '../../Styles/styles'
import { Box, Button, Card, CardActions, CardContent, List, ListItem, ListItemText, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import mic from '../../assets/svg/mic.svg'
// import pdfIcon from '../../assets/svg/pdfIcon.svg'
import { ErrorMessage, Form, Formik } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import { useRef } from 'react'
import { handleJoinUsModal, handleThankYouModal } from '../../redux'
import { useDispatch } from 'react-redux'
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import makeChangeBanner from '../../assets/images/makeChangeBanner.png'
import changeChampBanner from '../../assets/images/changeChampBanner.png'
import MobChangeChampBanner from '../../assets/MobileImages/MobChangeChampBanner.png'
import joinHands from '../../assets/MobileImages/joinHands.png'
import { Container } from '@material-ui/core'

const ChangeChampions = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch()
    const inputRefs: any = useRef([]);

    const focusNextInput = (index: number) => {
        const nextIndex = (index + 1) % inputRefs.current.length;
        inputRefs.current[nextIndex].focus();
    };

    const scrollToRef: any = useRef(null);
    const Formbody = () => {
        const initialValues = {
            name: '',
            phoneNumber: '',
            age:'',
            notes: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            state: '',
            city: '',
            zipcode: '',
            area: '',
            previousExperience: '',
            reasonForCYC: '',
            organizeDesc: ''
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Full Name is required'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            // Add validation for other fields here
        });

        const handleSubmit = async (values: any, actions: any) => {
            try {
                const formdata = {
                    "entry.1457409854": values.name,
                    "entry.2112335645": values.age,
                    "entry.2013398887": values.email,
                    "entry.1127805584": values.phoneNumber,
                    "entry.1255277500": values.addressLine1,
                    "entry.2099820937": values.addressLine2,
                    "entry.2017163081": values.city,
                    "entry.85036788": values.state,
                    "entry.369213499": values.zipcode,
                    "entry.387256554": values.area,
                    "entry.872610049": values.previousExperience,
                    "entry.367471151": values.reasonForCYC,
                    "entry.1596527535": values.organizeDesc,
                    "entry.1862221968": values.notes
                }

                await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/joinus`, formdata)
                actions.resetForm()
                dispatch(handleThankYouModal({ state: true, formName: 'joinus' }))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)
            }
        }

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => (

                    <Form className='form-join-now' onSubmit={props.handleSubmit}>
                        <div style={{ maxWidth: '400px', marginLeft: '80px', position: 'relative' }}>

                            <div className='contact-content'>
                                Full Name
                            </div>
                            <TextField
                                // 
                                id="name"
                                name="name"
                                inputRef={el => (inputRefs.current[0] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(0);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.name}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}
                            />
                            <ErrorMessage name="name">{msg => <div className='error-text'>{msg}</div>}</ErrorMessage>
                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                Phone Number
                            </div>
                            <TextField

                                id="phoneNumber"
                                name="phoneNumber"
                                inputRef={el => (inputRefs.current[2] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(2);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.phoneNumber}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />
                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                Address Line 1
                            </div>
                            <TextField

                                id="addressLine1"
                                name="addressLine1"
                                inputRef={el => (inputRefs.current[4] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(4);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.addressLine1}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />
                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                City
                            </div>
                            <TextField

                                id="city"
                                name="city"
                                inputRef={el => (inputRefs.current[6] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(6);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.city}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />
                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                Zip Code
                            </div>
                            <TextField

                                id="zipcode"
                                name="zipcode"
                                inputRef={el => (inputRefs.current[8] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(8);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.zipcode}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />
                            <div className='contact-content' style={{ marginTop: '20px' }}>
                                Which area or zone do you wish to represent as a Change Champion ?
                            </div>
                            <TextField

                                id="area"
                                name="area"
                                inputRef={el => (inputRefs.current[9] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(9);
                                    }
                                }}
                                multiline
                                // rows={6}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.area}
                                onChange={props.handleChange}
                                sx={{ width: '770px', marginTop: '1%', backgroundColor: '#ffffff', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }} // Adjust width as needed

                            /><div className='contact-content' style={{ marginTop: '20px' }}>
                                Previous Volunteering Experience ? <span style={{ fontStyle: 'italic' }}>( optional )</span>
                            </div>
                            <TextField

                                id="previousExperience"
                                name="previousExperience"
                                inputRef={el => (inputRefs.current[10] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(10);
                                    }
                                }}
                                multiline
                                // rows={6}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.previousExperience}
                                onChange={props.handleChange}
                                sx={{ width: '770px', marginTop: '1%', backgroundColor: '#ffffff', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }} // Adjust width as needed

                            /><div className='contact-content' style={{ marginTop: '20px' }}>
                                Why do you want to become a Change Champion for CYC ?
                            </div>
                            <TextField

                                id="reasonForCYC"
                                name="reasonForCYC"
                                inputRef={el => (inputRefs.current[11] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(11);
                                    }
                                }}
                                multiline
                                // rows={6}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.reasonForCYC}
                                onChange={props.handleChange}
                                sx={{ width: '770px', marginTop: '1%', backgroundColor: '#ffffff', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }} // Adjust width as needed

                            /><div className='contact-content' style={{ marginTop: '20px' }}>
                                Are you able to lead and organize collection drives in your area ?
                            </div>
                            <TextField

                                id="organizeDesc"
                                name="organizeDesc"
                                inputRef={el => (inputRefs.current[12] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(12);
                                    }
                                }}
                                multiline
                                // rows={6}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.organizeDesc}
                                onChange={props.handleChange}
                                sx={{ width: '770px', marginTop: '1%', backgroundColor: '#ffffff', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }} // Adjust width as needed

                            />
                            <div className='contact-content' style={{ marginTop: '20px' }}>
                                Any additional information you would like to share ? <span style={{ fontStyle: 'italic' }}>( optional )</span>
                            </div>
                            <TextField

                                id="notes"
                                name="notes"
                                inputRef={el => (inputRefs.current[13] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(13);
                                    }
                                }}
                                multiline
                                rows={6}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.notes}
                                onChange={props.handleChange}
                                sx={{ width: '770px', marginTop: '1%', backgroundColor: '#ffffff', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }} // Adjust width as needed

                            />

                            <Button
                                type="submit"
                                // disabled={props}
                                size="small"
                                variant="contained"
                                sx={{ backgroundColor: primaryColor, borderColor: primaryColor, ":hover": { backgroundColor: primaryColor }, height: '55px', width: '187px', marginTop: '3%', fontSize: '16px', marginBottom: '40px' }}
                            >
                                SUBMIT
                            </Button>
                            <br />
                            <br />
                        </div>

                        <div style={{ maxWidth: '400px', position: 'relative' }}>
                            <div className='contact-content'>
                                Email Address
                            </div>
                            <TextField

                                id="email"
                                name="email"
                                inputRef={el => (inputRefs.current[1] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(1);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.email}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />
                            <ErrorMessage name="email">{msg => <div className='error-text'>{msg}</div>}</ErrorMessage>

                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                Age
                            </div>
                            <TextField

                                id="age"
                                name="age"
                                inputRef={el => (inputRefs.current[3] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(3);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.age}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />

                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                Address Line 2
                            </div>
                            <TextField

                                id="addressLine2"
                                name="addressLine2"
                                inputRef={el => (inputRefs.current[5] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(5);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.addressLine2}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />
                            <div className='contact-content' style={{ marginTop: '4%' }}>
                                State
                            </div>
                            <TextField

                                id="state"
                                name="state"
                                inputRef={el => (inputRefs.current[7] = el)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        focusNextInput(7);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={props.values.state}
                                onChange={props.handleChange}
                                sx={{ height: '58px', width: '370px', marginTop: '2%', backgroundColor: '#FFFFFF', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' } }}

                            />

                        </div>
                    </Form>
                )}
            </Formik>
        );
    };
    const reasons = [
        {
            text: "<strong>1. Make a Difference:</strong> Every piece of clothing you help collect can bring warmth and comfort to someone.",
        },
        {
            text: "<strong>2. Lead the Way:</strong> Show your friends and family how even kids can make big changes in the community.",
        },
        {
            text: "<strong>3. Learn & Grow:</strong> Gain skills, make new friends, and be a part of something bigger than yourself.",
        },
        {
            text: "<strong>4. Earn Recognition:</strong> For your amazing efforts, you’ll receive a certificate of appreciation. Plus, we’ll give you a shoutout on our social media handles for everyone to see!",
        },
        {
            text: "<strong>5. Special Perks:</strong> Thanks to our corporate partners, you’ll also get exclusive benefits and goodies. It’s our way of saying ‘Thank You’ for your incredible work.",
        },
    ];

    return (

        <div className='parent-section'>
            <div className='cc-section-1'>
            <img src={ isMobile ? MobChangeChampBanner: changeChampBanner} width={'276px'} alt="Background" className="responsive-image" />

                <div className="about-overlay">

                    <div className='help-us-header'>
                        Change Champions
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                                <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                                    Home
                                </Link>
                                <Link
                                    // underline="hover"
                                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                                    color="inherit"
                                    to={"/changechampions"}
                                >
                                    Change Champions
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                </div>
            </div>
            <div className='changechampions-section'>
                <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '770px' }}>
                    <div style={{ margin: isMobile ? '7%' : '0', marginTop: 0 }}>

                        {!isMobile && <img src={makeChangeBanner} height={isMobile ? '208px' : '482px'} width={isMobile ? '100%' : '769px'} alt='makeChangeBanner' />}
                        <br />
                        <div className='header'>What is {isMobile && <br />} <span style={{ color: primaryColor }}>"Clean Your Closet" ?</span></div>
                        {isMobile && <div className='about-horizontal-line' />}

                        <br />
                        {isMobile && <><img src={makeChangeBanner} height={isMobile ? '208px' : '482px'} width={isMobile ? '100%' : '769px'} alt='makeChangeBanner' /><br /><br /> </>}

                        <div className='content'>"Clean Your Closet" is an initiative that believes in giving clothes a second chance. Instead of letting old clothes gather dust, we collect them and share them with those who need them. And the best part? Kids like you lead the way!
                        </div>
                        <br /><br />
                    </div>
                    {isMobile && <Container maxWidth='md' disableGutters style={{ backgroundColor: '#444444', padding: '30px' }}>
                        <Box>
                            <Typography sx={{ fontSize: '24px', lineHeight: '30.72px', fontWeight: '700', fontFamily: 'Lora' }} color="white" gutterBottom>
                                Why Become a <br /> <span style={{ color: '#f57c00' }}>Change Champion?</span>
                            </Typography>
                            <div className='about-horizontal-line' /><br />
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                }}
                                alt="Group of hands together"
                                src={joinHands}
                            />
                            <List>
                                {reasons.map((reason, index) => (
                                    <ListItem key={index} alignItems="flex-start">
                                        <ListItemText
                                            primary={<Typography variant="body1" color={'#ffffff'} dangerouslySetInnerHTML={{ __html: reason.text }} />}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Container>}
                    {!isMobile && <div className='header'>Why become a
                        <span style={{ color: primaryColor }}> Change Champion</span>
                        ?
                    </div>}
                    <br />
                    <div className='content'>
                        {!isMobile &&
                            <>
                                Make a Difference: Every piece of clothing you help collect can bring warmth and comfort to someone.
                                <br /><br />
                                Lead the Way: Show your friends and family how even kids can make big changes in the community.
                                <br /><br />
                                Learn & Grow: Gain skills, make new friends, and be a part of something bigger than yourself.
                                <br /><br />
                                Earn Recognition: For your amazing efforts, you'll receive a certificate of appreciation. Plus, we'll give you a shoutout on our social media handles for everyone to see!
                                <br /><br />
                                Special Perks: Thanks to our corporate partners, you'll also get exclusive benefits and goodies. It's our way of saying 'Thank You' for your incredible work.
                                <br /><br />
                            </>
                        }
                        <div style={{ margin: isMobile ? '7%' : '0' }}>

                            <div className='header'>Your Role</div>
                            {isMobile && <div className='about-horizontal-line' />}
                            <br />
                            <strong>Lead the Way:</strong> Show your friends and family how even kids can make big changes in the community.
                            <br /><br />
                            <strong>Spread the Word:</strong> Tell your friends, family, and neighbours about the initiative. The more people know, the more clothes we can collect!
                            <br /><br />
                            <strong>Stay Connected: </strong>Join our Change Champions WhatsApp group to share ideas, get updates, and be in the loop.
                            <br /><br />
                            <div className='header'>Your Responsibilities</div>
                            {isMobile && <div className='about-horizontal-line' />}

                            <br />
                            <div style={{ fontWeight: '700' }}>1. Get Permission from Society:</div>
                            <br />
                            <span className='dot'>&bull;</span> Approach the society's management or committee with a formal request to support the initiative.
                            <br />
                            <span className='dot'>&bull;</span> Approach the society's management or committee with a formal request to support the initiative.
                            <br />
                            <span className='dot'>&bull;</span> Address any concerns or questions they might have.
                            <br /><br />
                            <div style={{ fontWeight: '700' }}>2. Set Up Collection Centers:</div>
                            <br />
                            <span className='dot'>&bull;</span> Identify a suitable and accessible location within the society premises.
                            <br />
                            <span className='dot'>&bull;</span> Place the provided collection box/bin at the location.
                            <br />
                            <span className='dot'>&bull;</span> Display the guidelines for clothes donation prominently.
                            <br /><br />
                            <div style={{ fontWeight: '700' }}>3. Monitor:</div>
                            <br />
                            <span className='dot'>&bull;</span> Regularly check the collection box to ensure it's not overflowing.
                            <br />
                            <span className='dot'>&bull;</span> Ensure the clothes are in usable condition (washed, folded, no undergarments, no torn clothes).
                            <br /><br />
                            <div style={{ fontWeight: '700' }}>4. Coordinate with the Core Team:</div>
                            <br />
                            <span className='dot'>&bull;</span> Maintain regular communication with the core team.
                            <br />
                            <span className='dot'>&bull;</span> Schedule pick-ups when the collection box is nearly full.
                            <br />
                            <span className='dot'>&bull;</span> Ensure a smooth handover of collected clothes to the designated NGO delivery team.
                            <br /><br />
                            <div style={{ fontWeight: '700' }}>5. Reach Out to Family and Friends:</div>
                            <br />
                            <span className='dot'>&bull;</span> Share the initiative's details with family, friends, and acquaintances.
                            <br />
                            <span className='dot'>&bull;</span> Encourage them to donate and spread the word in their circles.
                            <br /><br />
                            <div style={{ fontWeight: '700' }}>6. Awareness & Promotion </div>
                            <br />
                            <span className='dot'>&bull;</span> Use promotional materials provided (posters, flyers) to spread awareness.
                            <br />
                            <span className='dot'>&bull;</span> Share the initiative's details on personal social media, encouraging more people to donate.
                            <br /><br />
                            <div style={{ fontWeight: '700' }}>7. Reporting & Feedback </div>
                            <br />
                            <span className='dot'>&bull;</span> Maintain a record of the number of clothes collected.
                            <br />
                            <span className='dot'>&bull;</span> Share periodic updates with the core team.
                            <br />
                            <span className='dot'>&bull;</span> Provide feedback on any challenges faced or areas of improvement.
                            <br /><br />
                            Being a Change Champion is a role of responsibility and impact. Your efforts will directly contribute to making a difference in countless lives. Let's work together, with dedication and passion, to ensure the success of "Clean Your Closet."
                            <br /><br />
                        </div>
                        {isMobile ? (
                            <div className='cc-section-3'>
                                <div style={{ margin: '5%', textAlign: 'center' }}>
                                    <div className='header' style={{ textAlign: 'center' }} ref={scrollToRef}>Ready to
                                        <span style={{ color: primaryColor }}> start ?</span>

                                    </div>
                                    <br />
                                    Reach out to us, and we'll provide you with all the materials and guidance you need. Let's work together to make sure no clothes go to waste and everyone feels cared for.<br /><br />Sign up here & our team will get in touch with you .
                                </div>
                                    <Button onClick={()=>dispatch(handleJoinUsModal(true))} size="small" variant="contained" sx={{ backgroundColor: primaryColor, ":hover": { backgroundColor: primaryColor }, height: '51px', width: '125px', fontSize: '16px' }}>JOIN NOW</Button>
                            </div>
                        ) : (
                            <>
                                <div className='header' ref={scrollToRef}>Ready to
                                    <span style={{ color: primaryColor }}> start ?</span>

                                </div>
                                <br />
                                Reach out to us, and we'll provide you with all the materials and guidance you need. Let's work together to make sure no clothes go to waste and everyone feels cared for.<br /><br />Sign up here & our team will get in touch with you .
                            </>
                        )}
                    </div>
                     {!isMobile && <><br/><br/></> }
                    {/* <div style={{ height: '50px', width: '770px' }}>
                        {error.state && <Alert severity="error">
                            {error.value}
                        </Alert>}
                    </div> */}
                    {!isMobile && <Formbody />}
                </div>
                {!isMobile && <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card sx={{ maxWidth: '370px', minWidth: '275px', maxHeight: '390px', backgroundColor: primaryColor, padding: '20px', color: '#FFFFFF' }}>
                        <CardContent>
                            <img src={mic} height={'69px'} width={'69px'} alt='mic' /><br /><br />
                            <Typography variant="h6" component="div">
                                Become a Volunteer
                            </Typography>
                            <Typography sx={{ mb: 1.5, fontSize: '16px' }}>
                                We need you now for world
                                <br /><br />
                                Take action and volunteer with us. Together, we can make a meaningful difference in the world
                            </Typography>

                        </CardContent>
                        <CardActions>
                            <Button onClick={() => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })} size="small" variant="contained" sx={{ backgroundColor: '#333333', ":hover": { backgroundColor: '#333333' }, height: '55px', width: '187px' }}>JOIN US NOW</Button>
                        </CardActions>
                    </Card><br /><br />
                    <br /><br /><br />
                    <div className='changechampions-footer-desc'>
                        <div className='changechampions-footer-header'>
                            Contact Info
                        </div>
                        <div className='changechampions-screen-options'>
                            <div className='changechampions-footer-who-we-are'><div className='changechampions-footer-phoneIcon-images'></div><div style={{ marginLeft: '10px', marginTop: '3%' }}>7506681015</div></div>
                        </div>
                        <div className='changechampions-screen-options'>
                            <div className='changechampions-footer-who-we-are'><div className='changechampions-footer-mail-images'></div><div style={{ marginLeft: '10px', marginTop: '3%' }}>cleanyourcloset122@gmail.com</div></div>
                        </div>

                    </div>
                </div>}
            </div>
            <div>
            </div>
        </div>
    )
}

export default ChangeChampions