// store.js
import { createSlice, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
};
// Reducer
const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState: {
        handleSignupModal: false,
        handleJoinUsModal: false,
        handleCorporateParterModal: false,
        handleNgoPartnerModal: false,
        handleHousingSocietyModal: false,
        handleThankYouModal: {
            state: false,
            formName: ''
        },
        handleAdminFormModal: false
    },
    reducers: {
        handleSignupModal: (state, action) => {
            state.handleSignupModal = action.payload
        },
        handleJoinUsModal: (state, action) => {
            state.handleJoinUsModal = action.payload
        },
        handleCorporateParterModal: (state, action) => {
            state.handleCorporateParterModal = action.payload
        },
        handleNgoPartnerModal: (state, action) => {
            state.handleNgoPartnerModal = action.payload
        },
        handleHousingSocietyModal: (state, action) => {
            state.handleHousingSocietyModal = action.payload
        },
        handleThankYouModal: (state, action) => {
            state.handleThankYouModal = action.payload
        },
        handleAdminFormModal: ( state, action ) => {
            state.handleAdminFormModal = action.payload
        },
        closeAllModals: (state) => {
            state.handleSignupModal = false
            state.handleJoinUsModal = false
            state.handleCorporateParterModal = false
            state.handleHousingSocietyModal = false
            state.handleAdminFormModal = false
            state.handleThankYouModal = {
                state: false,
                formName: ''
            }
        }
    },
})
const persistedReducer = persistReducer(persistConfig, authSlice.reducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export const {
    handleSignupModal,
    handleJoinUsModal,
    handleCorporateParterModal,
    handleNgoPartnerModal,
    handleHousingSocietyModal,
    handleThankYouModal,
    closeAllModals,
    handleAdminFormModal
} = authSlice.actions
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store);

export default store;
