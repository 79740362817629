import './about.css'
import founder from '../../assets/images/founder.png'
import MobFounder from '../../assets/MobileImages/MobFounder.png'
import { Breadcrumbs, Grid, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { primaryColor } from '../../Styles/styles'

const useStyles = makeStyles({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Adjust the opacity as needed
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
})
const AboutOwner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className='parent-section'>
      <Grid container className="aboutSection" style={{ height: isMobile ? '276px' : '424px' }}>
        <Grid item xs={12} className={classes.overlay}>
          <div className='help-us-header'>
            About Us
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                  Home
                </Link>
                <Link
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                  color="inherit"
                  to={"/about"}
                >
                  About
                </Link>
              </Breadcrumbs>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className='section-2' style={{ width: !isMobile ? '70%' : '90%', margin: isMobile ? '50px' : '100px' }}>
        <div className='about-us-desc'>
          <div className='about-us-header' style={{ width: '100%', color: '#000000' }}>
            About the Founder - <span color={isMobile ? primaryColor : '#000000'} >Subhaan</span>
            <div className='about-horizontal-line' />
            <br />
          </div>
          {isMobile && <img src={MobFounder} className='owner-image' alt='owner' />}

          <div style={{ width: !isMobile ? '62%' : '100%' }}>
            Subhaan, a 17-year-old high school student, embodies the spirit of innovation and a deep commitment to environmental sustainability. At just 14, he founded Clean Your Closet (CYC), demonstrating his early passion for making a tangible social impact. Over the years, he has meticulously nurtured CYC, guiding it to grow and expand its reach and influence.
            <br /><br />
            A nature enthusiast at heart, Subhaan is deeply invested in sustainable energy, viewing it as a crucial element for our future. His interests don't stop there; he's keenly involved in reengineering and low-cost innovation, believing that significant advancements don't always require substantial resources.
            <br /><br />
            Subhaan's passion for knowledge and sharing it with others is evident in his authorship of a book on Fusion Energy, showcasing his expertise and interest in cutting-edge sustainable technology, available for readers on Amazon. His drive to foster a similar spirit of innovation among his peers led him to establish a re-engineering club at his school. This club is a platform for younger students to delve into the basics of engineering and tackle challenging projects, such as building 3D printers, e-cycles, robots, and drones, nurturing the next generation of innovators and problem solvers.
            <br /><br />

          </div>
        </div>
        {/* <div className='owner-image' /> */}
        {!isMobile && <img src={founder} className='owner-image' alt='owner' />}
      </div>

    </div>
  )
}

export default AboutOwner