import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Box, Container, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { DataGrid } from '@mui/x-data-grid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useDispatch } from 'react-redux'
import { handleAdminFormModal } from '../../redux'
import AddCardsForm from './AddCardsForm'
import { Delete } from '@material-ui/icons'
const AdminPartner = () => {

  const [rows, setrows] = useState([])
  const [columns, setcolumns] = useState([])
  const [loading, setloading] = useState(false)
  const [selectedRow, setSelectedRow] = useState({ type: "", id: [] })
  const dispatch = useDispatch()
  const apiRef = useRef<any>('')

  const fetchData = async() => {
    try {
        setloading(true)
        const response = await axios.get('https://cricketspin.rewardzpromo.com/api/get-partner-data')

        const keys = Object.keys(response.data.data[0]);
        const filteredKeys = keys.filter(key => key !== '__v');
        const generatedColumns: any = [
          {
            field: 'id',
            headerName: 'ID',
            width: 80
          },
          
          ...filteredKeys.map(key => ({
          field: key,
          headerName: key === '_id' ? 'Id' : key.charAt(0).toUpperCase() + key.slice(1) ,
          width: 150
        }))]


        const generatedRows = response.data.data.map((row: any, index: any) => ({
          id: index + 1,
          ...row,
        }))


        setcolumns(generatedColumns)
        setrows(generatedRows)
        setSelectedRow({ type: "", id: [] })
    } catch (error) {
      console.log("🚀 ~ fetchData ~ error:", error)
    }finally{
      setloading(false)
    }
  }
  
  const handleChange = (selectionModel: any) =>{
    if(selectionModel.length===0){
      setSelectedRow({type:'',id:[]})
      return
    }
    let objectsTotDelete: any = { type: "edit", id: [] }
    selectionModel.forEach((record: any) => {
      objectsTotDelete.id.push(rows[record - 1]);
    });
    setSelectedRow(objectsTotDelete)
  }

  const handleEdit = () => {
    dispatch(handleAdminFormModal(true))
  }

  const handleUnselectAll = () => {
    apiRef.current.setRowSelectionModel([]);
    setSelectedRow({ type: "", id: [] });  // Reset selected rows
  };

  const handleDelete = async () => {
    try {
      const userConfirmed = window.confirm('Are you sure to delete?');
      if (!userConfirmed) {
        return
      }
      const arrayOfIdsToDelete: any[] = selectedRow.id.map((data: any)=>data._id)

      const response = await axios.post('https://cricketspin.rewardzpromo.com/api/delete-partner-data', { data: arrayOfIdsToDelete })
      console.log("🚀 ~ handleDelete ~ response:", response.data)
      handleUnselectAll()
      fetchData()
    } catch (error: any) {
      console.log("🚀 ~ handleDelete ~ error:", error)
      alert(error?.message)
    }
  }

  useEffect(()=>{
    if(columns.length ===0 ){
      fetchData()
    }
  },[])

  return (

    <Box sx={{ width: '90vw', height: '100vh', bgcolor: '#f7f7f7', display: 'flex', justifyContent: 'end', alignItems: 'center', flexDirection: 'column' }}>
      <AddCardsForm selectedRow={selectedRow} columns={columns} handleUnselectAll={handleUnselectAll} fetchData={fetchData} />
      <Box sx={{ width: '80vw', display: 'flex', justifyContent: 'end', marginTop: '-15%' }}>
        {selectedRow.id.length === 1 && <IconButton onClick={handleEdit} component="span">
          <EditOutlinedIcon />
        </IconButton>
        }

        {selectedRow.id.length === 0 &&
          <IconButton onClick={() => dispatch(handleAdminFormModal(true))} component="span">
            <AddIcon />
          </IconButton>
        }

        { selectedRow.id.length > 0 &&
          <IconButton onClick={handleDelete} component="span">
            <Delete />
          </IconButton>
        }

      </Box>
      <Container maxWidth={"xl"} sx={{ width: '100%', height: '87%', borderRadius: '10px', bgcolor: '#FFFFFF', display: 'flex', flexDirection: 'column', marginBottom: '3%' }}>
        <DataGrid
          loading={loading}
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          sx={{
            flexGrow: 1,
            width: '100%',
            border: 'none'
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={handleChange}
        />
      </Container>
    </Box>

  )
}

export default AdminPartner