import './partner.css';
import { Breadcrumbs, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { primaryColor } from '../../Styles/styles';

const PartnerInDetail = () => {
    const location = useLocation()

    const data = [{
        name: 'Goonj',
        desc: 'Since 1999, Goonj has built a network of communities from urban to village India, channelizing material as a tool to address crucial gaps in rural infrastructure, water, environment, livelihood, education, health, disaster relief and rehabilitation. Its engagement with both the urban and rural population has galvanized a mass civic participation in addressing basic but neglected issues. Goonj’s model of development implemented through its various Initiatives proposes a more inclusive alternative economy where everyone is an equal stakeholder in the process. <br/><br/><br/> <span style="font-weight: bold;color:#000000">Processing Center</span> <br/><br/> Goonj Processing Centers are the first point where all material collected from the cities goes through a rigorous process of sorting, segregating, repairing and packing before dispatching it to the remotest villages of India. Why Processing? Because the dignity of the receiver and giving with dignity is at the centre of all that goonj does. Because when people in remote villages of India work for many days on building a bamboo bridge or digging a well or building a community hall, they deserve a reward… NOT Charity.',
        link: 'https://goonj.org/'
    },
    {
        name: 'Prayas ek Koshish',
        desc: `PRAYAS Ek Koshish is a non funded organisation working on Gender & Human Rights since 2004 in Mumbai.
        <br/><br/>
        In addition to their core work on gender and human rights, Prayas Ek Koshish also engages in charitable activities, such as accepting donations of clothing items and distributing them to individuals and communities in need. This aspect of their work highlights their commitment to providing practical support and assistance to vulnerable populations, further emphasizing their holistic approach to social welfare and empowerment.
        <br/><br/>
        By accepting and distributing donated clothing, Prayas Ek Koshish addresses immediate needs and contributes to the well-being and dignity of those less fortunate. This initiative not only supports individuals in need but also promotes a culture of giving and community support, encouraging others to contribute and be part of positive social change. Through such actions, Prayas Ek Koshish demonstrates a comprehensive approach to social justice, combining advocacy and direct support to improve lives and foster a more inclusive and compassionate society.
        `,
        link: 'https://www.facebook.com/PrayasEkKoshish/'
    }, {
        name: 'The Bittiya Institute',
        desc: `The Bittiya Institute, with branches in Rahwai and New Bombay (Navi Mumbai), is an organization dedicated to advancing education for girls and facilitating skill development for rural women. This institution plays a pivotal role in empowering women and girls, particularly focusing on underserved communities where access to education and vocational training is limited.
        <br/><br/>
        Education for the Girl Child: Bittiya Institute's initiatives in promoting girl child education are critical in regions where gender disparities in education persist. By providing educational opportunities, resources, and support, the institute aims to break down barriers that girls face in accessing education, thereby fostering gender equality and enabling girls to pursue their aspirations.
        <br/><br/>
        Skill Development for Rural Women: Understanding the importance of economic empowerment, Bittiya Institute offers skill development programs tailored to rural women. These programs are designed to equip women with practical skills and knowledge, enabling them to secure employment or start their own businesses, which can lead to improved economic status and independence.
        <br/><br/>
        Charitable Activities in New Bombay: In New Bombay, the Bittiya Institute is known for its charitable initiatives. One of the key activities includes collecting and distributing clothing to the less fortunate. This act of charity not only addresses immediate needs by providing essential items to those in need but also fosters a sense of community and solidarity, encouraging others to contribute to the welfare of the less privileged.
        `,
        link: 'https://www.facebook.com/media/set/?set=a.139214602848379.18000.139213592848480&type=3'
    }]

    const item = data.find(obj => obj.name === location.state.name);

    return (
        <>
            <div className='parent-section'>
                <div className='section-1'>
                    <div className="about-overlay">
                        <div className='partner-header'>
                            Partners
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                                    <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                                        Home
                                    </Link>
                                    <Link
                                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                                        color="inherit"
                                        to={"/partner"}
                                    >
                                        Partners
                                    </Link>
                                    <div>{location.state.name}</div>
                                </Breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='partner-data' dangerouslySetInnerHTML={{ __html: item?.desc || '' }} />
                <div style={{ marginBottom: '100px' }}>
                    <Button onClick={()=>window.open(item?.link)} variant='contained' className='card-button' sx={{ backgroundColor: primaryColor, color: '#FFFFFF', borderColor: primaryColor, ":hover": { backgroundColor: '#FF7000', borderColor: '#FF7000', borderWidth: 1 } }}>VISIT</Button>
                </div>

            </div>

        </>
    )
}

export default PartnerInDetail