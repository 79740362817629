import Modal from '@material-ui/core/Modal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import close from '../../assets/svg/close.svg';
import './../../Views/About/about.css';
import { handleThankYouModal } from '../../redux';
import { useMediaQuery } from '@mui/material';

const ThankYouModal = () => {
    const classes = useStyles();
    const store: any = useSelector((store: any) => store.handleThankYouModal);
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const data = [
        {
            'name': 'donor',
            'title': 'Thank You for Your Donation!',
            'description': `Thank you for signing up to donate with Clean Your Closet (CYC)! We deeply appreciate your willingness to contribute and make a difference. Your generosity will help us bring warmth and support to those in need.
            <br/><br/>
            Our team will review your submission and get in touch with you soon to confirm the pickup details. If you have any questions or need to make changes to your donation appointment, please feel free to reach out to us.
            <br/><br/>
            Thank you once again for being a part of our mission to create a positive impact through kindness and sharing. Your contribution is not just a donation; it's a beacon of hope and support for our community.
            <br/>
            The CYC Team`
        },
        {
            'name': 'corporatePartner',
            'title': 'Thank You for Partnering with Clean Your Closet!',
            'description': `We greatly appreciate your company's commitment to making a difference through the CYC initiative. Your support plays a crucial role in expanding our reach and enhancing the impact of our donation drives. We believe this partnership will not only benefit those in need but also foster a spirit of community and giving within your organization.
            <br/><br/>
            A member of our team will be in touch soon to discuss the next steps and how we can best collaborate to achieve our shared goals. Should you have any questions or require further information before our conversation, please don't hesitate to contact us.
            <br/><br/>
            Thank you once again for your support and willingness to contribute to a meaningful cause.
            <br/><br/>
            Best Regards,  
            <br/>
            The CYC Team
            `
        },
        {
            'name': 'housingSociety',
            'title': 'Thank You for Your Initiative!',
            'description': `We are grateful to your housing society for taking the step to support Clean Your Closet (CYC). Your participation is crucial in driving our mission forward, and we look forward to collaborating with you on this collection drive.
            <br/><br/>
            Our team will be in touch soon to discuss the next steps and ensure everything is set up for a successful drive. If you have any questions or further details to share with us in the meantime, please don't hesitate to reach out.
            <br/><br/>
            Thank you again for your commitment to making a difference. Together, we can bring positive change and support those in need.
            <br/><br/>
            Best Regards,  
            <br/>
            The CYC Team
            `
        },
        {
            'name': 'joinus',
            'title': 'Welcome to the Team, and Thank You!',
            'description': `We're thrilled that you've decided to join CYC as a Change Champion. Your initiative and dedication to leading collection drives in your community are invaluable to our mission. Our team will review your submission and contact you soon to provide further details and support for your activities as a Change Champion. Together, we'll work towards making a significant difference in the lives of others and promoting sustainability and kindness in our communities.
            <br/><br/>
            If you have any questions before we reach out, please feel free to contact us. Thank you again for stepping up and choosing to make a difference with CYC.
            <br/><br/>
            Best Regards,  
            <br/>
            The CYC Team
            `
        },
        {
            'name': 'ngoPartner',
            'title': 'Thank You for Your Interest in Partnering with CYC!',
            'description': `Your NGO's willingness to collaborate with Clean Your Closet is a significant step towards making a greater impact on those in need and promoting sustainability. We value your expertise and commitment to making a difference in the community.
            <br/><br/>
            A member of our CYC team will review your information and reach out to discuss potential collaboration and how we can work together effectively. We are excited about the possibility of joining forces to ensure that donated clothing finds a meaningful and beneficial use.
            <br/><br/>
            If you have any immediate questions or additional information to share, please do not hesitate to contact us. We look forward to the opportunity to partner with your organization and extend our collective impact.
            <br/><br/>
            Best Regards,  
            <br/>
            The CYC Team
            `
        },
        {
            'name': 'message',
            'title': 'Thank You for reaching us out!',
            'description': ``
        }

    ]
    const item = data.find(obj => obj.name === store.formName);

    const handleClose = () => {
        dispatch(handleThankYouModal({ state: false, formName: '' }))
    };

    return (
        <Modal
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1300, overflow: 'auto' }}
            open={store.state}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={{ width: '100%', maxWidth: '942px', margin: 'auto', padding: "20px 25px", backgroundColor: '#F1F1F1', boxShadow: "5px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ fontFamily: 'Lora', fontSize: isMobile ? 18 : 40, fontWeight: 600  }} id="simple-modal-title">{item?.title}</p>
                    <img src={close} alt="logo" onClick={handleClose} style={{ cursor: "pointer" }} />
                </div>
                <div className='horizontal-line' style={{ marginTop: '-10px', height: '2px', width: "100px", backgroundColor: "#FF7000",
                }} />

                <div style={{ fontFamily: 'Roboto', color: '#666666', marginTop: '30px', fontSize: isMobile ?'12px': '16px', lineHeight: '23px' }} dangerouslySetInnerHTML={{ __html: item?.description || '' }} />
            </div>
        </Modal>
    );
};

export default ThankYouModal;


// Define styles for the modal
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1300,
        overflow: 'auto',
    },
    paper: {
        width: '100%',
        maxWidth: '942px',
        margin: 'auto',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: '#F1F1F1',
        boxShadow: theme.shadows[5],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));
