import * as React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link from React Router
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import useMediaQuery from '@mui/material/useMediaQuery'; // Import useMediaQuery
import './Navbar.css';
import { primaryColor } from '../../Styles/styles';
import { Menu, MenuItem } from '@mui/material';



const ResponsiveAppBar = () => {

  const pages = [
    { name: 'Home', path: '/' },
    {
      name: 'About', path: '/about', subPages: [ // Add subPages array for dropdown menu
        { name: 'About Us', path: '/about' },
        { name: 'About Founder', path: '/aboutowner' }
      ]
    },
    // { name: 'Causes', path: '/' },
    { name: 'Change Champions', path: '/changechampions' },
    { name: 'Get Involved', path: '/getInvolve' },
    { name: 'Partners', path: '/partner' },
    { name: 'Gallery', path: '/pages' },
    { name: 'Contact', path: '/contactus' }
  ];
  const location = useLocation();
  const theme = useTheme(); // Access the theme
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check if it's mobile view
  const [anchorEl, setAnchorEl] = React.useState(null); // State for menu anchor

  const handleMenuOpen = (event: { currentTarget: React.SetStateAction<null>; }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="static" className='navbar'>
      <Container maxWidth="lg">
        <Toolbar disableGutters={!isMobile}> {/* Disable gutters for mobile view */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
            {/* Map over the pages array and create Button components with Link */}
            {pages.map((page) => (
              // Render dropdown menu for 'About' page
              page.subPages ? (
                <React.Fragment key={page.name}>
                  <Button
                    aria-controls={page.name}
                    aria-haspopup="true"
                    onClick={(event: any) => {
                      handleMenuOpen(event)
                    }}
                    sx={{
                      my: 2,
                      mx: 1,
                      color: location.pathname === page.path ? primaryColor : '#FFFFFF',
                      display: isMobile ? 'inline-block' : 'block',
                      fontSize: '16px',
                      lineHeight: '24px'
                    }}
                  >
                    {page.name}
                  </Button>
                  <Menu
                    id={page.name}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      sx: { backgroundColor: '#333333' } // Set red background color for menu
                    }}
                  >
                    {page.subPages.map((subPage) => (
                      <MenuItem
                        key={subPage.name}
                        component={Link}
                        to={subPage.path}
                        onClick={handleMenuClose} // Close menu on click
                        sx={{ color: '#FFFFFF', ":hover": { color: primaryColor } }}
                      >
                        {subPage.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </React.Fragment>
              ) : (
                <Button
                  key={page.name}
                  component={Link}
                  to={page.path}
                  sx={{
                    my: 2,
                    mx: 1,
                    color: location.pathname === page.path ? primaryColor : '#FFFFFF',
                    display: isMobile ? 'inline-block' : 'block',
                    fontSize: '16px',
                    lineHeight: '24px'
                  }}
                >
                  {page.name}
                </Button>
              )
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
