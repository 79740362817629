import { Box, Button, FormControl, Grid, MenuItem, Modal, Select, Slide, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import './donorsignupform.css';
import axios from 'axios';
import { primaryColor } from '../../Styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { handleSignupModal, handleThankYouModal } from '../../redux';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import close from '../../assets/svg/close.svg';

const DonorSignupForm = () => {

    const store: any = useSelector((store: any) => store.handleSignupModal);
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const Formbody = () => {
        const initialValues = {
            name: '',
            phoneNumber: '',
            selectedDate: new Date().toISOString().split('T')[0],
            notes: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            time: '',
            state: '',
            city: '',
            zipcode: '',
        };
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const validationSchema = Yup.object().shape({
            name: Yup.string().required('Full Name is required').max(100),
            email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Invalid email'
                )
                .required('Email is required'),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number format').required('Phone Number is required'),
            addressLine1: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            addressLine2: Yup.string().max(200, 'Address must be at most 100 characters').required('Address is required'),
            city: Yup.string().max(50, 'City name must be at most 100 characters').required('City is required'),
            state: Yup.string().max(50, 'State name must be at most 50 characters').required('State is required'),
            zipcode: Yup.string().length(6, 'Zip code must be of 6 characters').matches(/^\d+$/, 'Zip code must contain only numbers').required('Zip code is required'),
            selectedDate: Yup.date().min(today, 'Selected date must be today or a future date').required('Preferred Pickup Date is required'),
            time: Yup.string().required('Please select a time'),
            notes: Yup.string().max(500, 'Max 500 characters')

        });

        const handleSubmit = async (values: any) => {
            try {
                
                const formdata = {
                    "entry.126367815": values.name,
                    "entry.308065086": values.email,
                    "entry.13885288": values.phoneNumber,
                    "entry.275693555": values.addressLine1,
                    "entry.1368034701": values.selectedDate,
                    "entry.2065237756": values.time,
                    "entry.991046444": values.notes
                }

                await axios.post(`https://cricketspin.rewardzpromo.com/api/submitForm/signup`, formdata)
                dispatch(handleSignupModal(false))
                dispatch(handleThankYouModal({ state: true, formName: 'donor' }))
            } catch (error) {
                console.log("🚀 ~ handleSubmit ~ error:", error)

            }
        }
        
        const handleClose = () => {
            dispatch(handleSignupModal(false))
        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container className='form-donate-now' sx={{ width: isMobile ? '100%' : '942px' }}>
                            <div style={{ width: '100%' }}>
                                <div className='form-footer-header'>
                                    Donor Signup Form
                                    <img src={close} alt="logo" onClick={handleClose} />
                                </div>
                                <div className='form-horizontal-line' />

                                <div className='form-message'>Welcome to CYC! Your contribution makes a significant difference. Please fill out the form below to schedule a clothing donation pickup.</div>
                            </div>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Full Name
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="name" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Email Address
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    type='email'
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="email" />}

                                    // fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Phone Number
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="phoneNumber" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Address Line 1
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine1" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Address Line 2
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="addressLine2"
                                    name="addressLine2"
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="addressLine2" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    City
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="city"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="city" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    State
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="state"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="state" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Zip Code
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="zipcode"
                                    name="zipcode"
                                    value={formik.values.zipcode}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="zipcode" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Preferred Pickup Date
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ marginTop: '2%', width: isMobile ? '340px' : '370px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="selectedDate"
                                    name="selectedDate"
                                    type="date"
                                    value={formik.values.selectedDate}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="selectedDate" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Preferred Time
                                </div>
                                <FormControl fullWidth error={Boolean(formik.touched.time && formik.errors.time)}>
                                    <Select
                                        className='form-textfield'
                                        sx={{
                                            marginTop: '2%',
                                            width: isMobile ? '340px' : '370px',
                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' },
                                            '& .MuiFormHelperText-root': { color: 'red' },
                                        }}
                                        id="time"
                                        name="time"
                                        value={formik.values.time}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem value="Morning">Morning</MenuItem>
                                        <MenuItem value="Afternoon">Afternoon</MenuItem>
                                        <MenuItem value="Evening">Evening</MenuItem>
                                    </Select>
                                    {formik.touched.time && formik.errors.time ? (
                                        <Typography style={{ fontSize: '12px', margin:2 }} sx={{ color: 'red' }}>
                                        {formik.errors.time}
                                      </Typography>
                                    ) : null}
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='form-content'>
                                    Additional Notes
                                </div>
                                <TextField
                                    className='form-textfield'
                                    sx={{ height: '170px', marginBottom: isMobile? '30%' : '10%', marginTop: '2%', width: isMobile ? '340px' : '765px', '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FF7000' }, '& .MuiFormHelperText-root': { color: 'red' } }}
                                    id="notes"
                                    name="notes"
                                    multiline
                                    rows={6}
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    helperText={<ErrorMessage name="notes" />}
                                    fullWidth
                                />
                                <div className='box-button'>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            height: '55px',
                                            width: '187px',
                                            fontSize: '16px',
                                            marginLeft: isMobile ? '100px' : '300px',
                                            backgroundColor: primaryColor,
                                            ":hover": { backgroundColor: primaryColor },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        
                    </Form>
                )}
            </Formik>
        );
    };

    return (
        <Modal
            open={store}
            onClose={() => dispatch(handleSignupModal(false))}
            style={{ marginTop: isMobile ? '20%' : '5vh' }}
        >
            <Slide direction="up" in={store} mountOnEnter unmountOnExit>
                <Box
                    className="modal"
                    sx={{
                        borderRadius: '8px',
                    }}
                >
                    <Formbody />
                </Box>
            </Slide>
        </Modal>
    )
}

export default DonorSignupForm