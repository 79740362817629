import './gallery.css'
import image1 from '../../assets/images/IMG_3725.jpg'
import image2 from '../../assets/images/IMG_9603.jpg'
import image3 from '../../assets/images/IMG_3733.jpg'
import image4 from '../../assets/images/IMG_3735.jpg'
import image5 from '../../assets/images/IMG_3741.jpg'
import image6 from '../../assets/images/IMG_3753.jpg'
import image7 from '../../assets/images/IMG_4997.jpg'
import image8 from '../../assets/images/IMG_9511.jpg'
import image9 from '../../assets/images/IMG_9790.jpg'
import image10 from '../../assets/images/Image10.jpg'
import image11 from '../../assets/images/Image11.jpg'
import image12 from '../../assets/images/Image12A.jpg'
import image13 from '../../assets/images/Image13.jpg'
import image14 from '../../assets/images/Image14.jpg'
import image15 from '../../assets/images/Image15.jpg'
import image16 from '../../assets/images/Image16.jpg'
import image17 from '../../assets/images/Image17.jpg'
import image18 from '../../assets/images/Image18A.jpg'
import { Breadcrumbs, Grid, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'

const Gallery = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const imagesForWeb = [ image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18 ];
    // const imagesForMob = [ image1, image2, image3, image4, image5, image6, image7, image8 ];
    const fetchImages = async() =>{
        try {
            const response = await axios.get('https://cricketspin.rewardzpromo.com/api/fetch-images')
            console.log("🚀 ~ fetchImages ~ response:", response)
        } catch (error) {
            console.log("🚀 ~ fetchImages ~ error:", error)
        }
    }
    
    useEffect(()=>{
        fetchImages()
    },[])
    
    return (
        <>
            <div className='pages-parent-section'>
                <div className='pages-section-1'>
                    <div className="about-overlay">
                        <div className='pages-header'>
                            Gallery
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#FFFFFF' }}>
                                    <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} to={"/"}>
                                        Home
                                    </Link>
                                    <Link
                                        // underline="hover"
                                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                                        color="inherit"
                                        to={"/pages"}
                                    >
                                        Gallery
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex',alignItems: 'center', justifyContent: 'center', width: isMobile ? '90%' : '60%'}}>
                    <Grid container spacing={2}>
                        {imagesForWeb.map((image, index) => (
                            <Grid item xs={isMobile ? 6 : 4} key={index}>
                                <div
                                    style={{
                                        width: '100%',
                                        // paddingTop: '100%', // This ensures the div is square
                                        position: 'relative', // For absolute positioning of the image inside the div
                                        overflow: 'hidden',
                                        aspectRatio: '1 / 1',
                                    }}
                                >
                                    <img
                                        src={image}
                                        alt={`img-${index}`}
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            display: 'block',
                                            padding: '4px',
                                        }}
                                    />
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>

            </div>
            <br/><br/>
        </>
    )
}

export default Gallery