import './getInvolved.css';
import { Box, Button, Collapse, Container, Divider, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import { primaryColor } from '../../Styles/styles';
import { useDispatch } from 'react-redux';
import { handleCorporateParterModal, handleHousingSocietyModal, handleJoinUsModal, handleNgoPartnerModal, handleSignupModal } from '../../redux';
import donateClothing from '../../assets/images/donateClothing.png';
import MobDonateClothing from '../../assets/MobileImages/MobDonateClothing.png';
import NgoBanner from '../../assets/images/NgoBanner.png';
import groups from '../../assets/svg/groups.svg';
import corporateBanner from '../../assets/images/corporateBanner.png';
import housingSociety from '../../assets/svg/housingSociety.svg';
import { useRef, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import MobGetInvolveBanner from '../../assets/MobileImages/MobGetInvolveBanner.png'
import getInvolveBanner from '../../assets/images/getInvolveBanner.png'

type OpenKeys = 'donor' | 'hs' | 'corporate' | 'volunteer' | 'ngo';

const BlackDiv = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch()
    const sectionRefs: any = useRef([]);

    // Function to scroll to a specific section
    const scrollToSection = (index: string | number) => {
        sectionRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const items = [
        {
            id: 1,
            key: 'donor' as OpenKeys,
            primary: "For Donor",
            secondary: "Donate Now",
            img: MobDonateClothing,
            title: "Donate Clothing",
            desc: "Your journey as a donor with CYC is not just about parting with clothes you no longer need; it's about embracing an opportunity to make a significant impact. With our easy home pickup service, donating has never been simpler. Sign up, and we'll arrange a doorstep pickup at your convenience. Your contribution plays a vital role in promoting sustainability, reducing waste, and providing essential clothing to those in need. But don't stop there – be an advocate for change by sharing this initiative with your family and friends, encouraging them to join our community of donors. Your voice can amplify our mission, inspiring others to contribute and helping us spread warmth and care t-ough every donated item"
        },
        {
            id: 2,
            key: 'hs' as OpenKeys,
            primary: "For Housing Society",
            secondary: "Host a Collection",
            img: housingSociety,
            title: "For Housing Society",
            desc: "Host a collection drive in your housing society. We'll provide the boxes and posters, and help you set everything up. Just choose a day for the collection, and we'll give you message templates to inform your residents through WhatsApp and posters. Sign up, and our team will contact you to arrange the details. It's a simple way to help out and bring your community together."
        },
        {
            id: 3,
            key: 'corporate' as OpenKeys,
            primary: "For Corporates",
            secondary: "Add to CSR",
            img: corporateBanner,
            title: "For Corporates",
            desc: "Corporate involvement is vital to expanding our reach and enhancing our impact. By partnering with CYC, your company can serve as a collection point, aiding in the gathering and distribution of clothing donations. Beyond offering space, there's an opportunity to engage your workforce in this noble cause, fostering a culture of community support and giving within your organization.But it doesn't end there. Your company can also provide invaluable support through sponsorships, marketing assistance, and even rewards for active participants. These contributions not only aid in the logistical aspects of our initiative but also amplify our message, encouraging more widespread participation.Moreover, your involvement with CYC offers a tangible way to enhance your Corporate Social Responsibility (CSR) profile. It demonstrates a commitment to sustainability and community welfare, resonating with employees, clients, and the broader public."
        },
        {
            id: 4,
            key: 'volunteer' as OpenKeys,
            primary: "For Volunteer",
            secondary: "Join Now",
            img: housingSociety,
            title: "Become Change Champion",
            desc: "Become a Change Champion with CYC and take the lead in your area or zone. By collaborating with our team, you'll organize collection drives, engage local communities, and become a key figure in promoting our cause. We'll support you with the necessary tools and guidance to mobilize your community effectively. This role is not just about leading; it's about making a tangible impact and inspiring others to join in. Sign up to be the change in your community and help us extend our reach."
        },
        {
            id: 5,
            key: 'ngo' as OpenKeys,
            primary: "For NGO",
            secondary: "Become Partner",
            img: NgoBanner,
            title: "NGOs- Partner with us",
            desc: "At CYC, we are eager to broaden our network of NGO partners across India. We are looking for organizations that not only distribute clothing to those in need but are also involved in recycling or repurposing clothes. Our aim is to ensure that every donated item is utilized to its fullest potential, whether it's providing warmth and dignity to someone or being transformed into a new product.As an NGO partner, you'll play a crucial role in the lifecycle of donated clothes. Your expertise in distribution, repurposing, and recycling will help maximize the impact of each donation. We are committed to working closely with our NGO partners to align our efforts, ensuring that the process is efficient, respectful, and beneficial for all parties involved.We welcome NGOs that accept various types of clothing items, as our goal is to cater to a wide range of needs and promote sustainability in every aspect of our initiative. By partnering with CYC, you'll be joining a network that is dedicated to making a tangible difference in communities and the environment."
        }
    ];

    const [open, setOpen] = useState<Record<OpenKeys, boolean>>({
        'donor': false,
        'hs': false,
        'corporate': false,
        'volunteer': false,
        'ngo': false
    });

    const handleClick = (text: OpenKeys) => {

        // setOpen(open === index ? null : index);
        setOpen((prevState: any) => ({
            ...prevState,
            [text]: !prevState[text],
        }));
    };

    const handleSubmit = (id: Number) =>{
        switch(id){
            case 1:
                dispatch(handleSignupModal(true))
                break
            case 2:
                dispatch(handleHousingSocietyModal(true))
                break
            case 3:
                dispatch(handleCorporateParterModal(true))
                break
            case 4:
                dispatch(handleJoinUsModal(true))
                break
            case 5:
                dispatch(handleNgoPartnerModal(true))
                break
            default: 
                dispatch(handleSignupModal(true))
                return
        }
    }
    return (
        <div className='blackDiv'>

            <div className="involve-container">
                <img style={{ position : 'absolute' }} src={isMobile ? MobGetInvolveBanner : getInvolveBanner} width={'276px'} alt="Background" className="responsive-image" />

                <div className="getInvolve-header-overlay">

                    <div className="involve-text-center">
                        <span className="main-text">#GetInvolved
                            <span className="sub-text" style={{ marginLeft: isMobile ? '10px' : '20px', fontSize: isMobile ? '32px': '54px' }}>with </span>
                        </span><br/>
                        {/* <p className="sub-text" style={{ textAlign: 'start' }}> */}
                            Clean Your Closet
                        {/* </p> */}
                        <p className='involve-content-text'> Lead by example: Donate with CYC, encouraging<br /> others to join the movement.</p>
                        {!isMobile && <Button size='large' variant="contained" sx={{ width: '165px', height: '55px', backgroundColor: primaryColor, marginTop: '2%', ":hover": { backgroundColor: primaryColor } }} onClick={() => dispatch(handleSignupModal(true))} >Donate Now</Button>}
                    </div><br />
                </div>
            </div>

            {isMobile ?
                <Box sx={{ width: '100%', bgcolor: 'background.paper', maxWidth: '90%' }}>
                    <br />
                    <br />
                    {items.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <List sx={{ borderWidth: '1px', borderRadius: '4px', borderStyle: 'solid', borderColor: '#E6E6E6', margin: 1, marginTop: '20px' }}>
                                <ListItem onClick={() => handleClick(item.key)} disablePadding sx={{ padding: '16px', gap: '16px' }}>
                                    <img src={groups} alt='groups'/>
                                    <ListItemText primary={item.primary} secondary={item.secondary} secondaryTypographyProps={{ fontSize: '12px', fontWeight: '400' }} />
                                    {open[item.key] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </ListItem>

                                <Collapse in={open[item.key]}>
                                    <Divider />
                                    <Box sx={{ padding: 2, objectFit: 'cover', backgroundPosition: 'center' }}>
                                        <Typography fontSize={'20px'} fontWeight={'700'} lineHeight={'25.6px'}>{item.title}</Typography>
                                        <br />
                                        <Box
                                            sx={{
                                                height: '186px',
                                                objectFit: 'cover'
                                            }}
                                        >
                                            <img src={item.img} height={'100%'} width={'100%'} alt={`${item.title}`} />
                                        </Box>
                                        <p className='collapse'>{item.desc}</p>
                                    </Box>
                                    <Button
                                        size={isMobile ? 'medium' : 'large'}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: primaryColor,
                                            ':hover': {
                                                backgroundColor: primaryColor,
                                            },
                                            height: '45px',
                                            marginLeft: '100px',
                                            bottom: '10px'
                                        }}
                                        onClick={()=> handleSubmit(item.id)}
                                    >
                                        {item.secondary}
                                    </Button>
                                </Collapse>
                            </List>
                        </React.Fragment>
                    ))}
                    <br /><br />
                    <Divider />
                </Box> :
                <>
                    <div className='involve-section'>
                        <div className='involve-donate-now' onClick={() => scrollToSection(1)}>
                            Become a Donor
                            <div className='involve-header'>Donate Now</div>
                        </div>
                        <div className='involve-now' onClick={() => scrollToSection(2)}>
                            Housing Society
                            <div className='involve-header'>Host a collection</div>

                        </div>
                        <div className='involve-join-now' onClick={() => scrollToSection(3)}>
                            Become a Change Champion
                            <div className='involve-header'>Join Us Now</div>

                        </div>
                        <div className='involve-now' onClick={() => scrollToSection(4)}>
                            Corporates
                            <div className='involve-header'>Make it a part of CSR!</div>

                        </div>
                        <div className='involve-join-now' onClick={() => scrollToSection(5)}>
                            Partner with us
                            <div className='involve-header'>NGOs</div>

                        </div>
                    </div>
                    <Container ref={(ref) => (sectionRefs.current[1] = ref)} maxWidth="lg" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', gap: '150px', marginTop: '300px' }} fixed className='parent-donate-clothing'>
                        <Box sx={{ height: '100%', width: '50%' }} >
                            <div className='involve-text-center' style={{ color: '#000000' }}>
                                Donate Clothing
                            </div>
                            <div className='horizontal-line' />
                            <br />
                            <div className='involve-desc' style={{ maxWidth: '558px', textAlign: 'start', color: '#666666', marginTop: '25px' }}>
                                Your journey as a donor with CYC is not just about parting with clothes you no longer need; it's about embracing an opportunity to make a significant impact. With our easy home pickup service, donating has never been simpler. Sign up, and we'll arrange a doorstep pickup at your convenience. Your contribution plays a vital role in promoting sustainability, reducing waste, and providing essential clothing to those in need. But don't stop there – be an advocate for change by sharing this initiative with your family and friends, encouraging them to join our community of donors. Your voice can amplify our mission, inspiring others to contribute and helping us spread warmth and care t-ough every donated item.
                            </div>
                            <Button
                                size={isMobile ? 'medium' : 'large'}
                                variant="contained"
                                sx={{
                                    backgroundColor: primaryColor,
                                    ':hover': {
                                        backgroundColor: primaryColor,
                                    },
                                    height: isMobile ? '45px' : '55px',
                                    width: isMobile ? '130px' : '165px',
                                    marginTop: '25px'
                                }}
                                onClick={() => dispatch(handleSignupModal(true))}
                            >
                                Donate Now
                            </Button>
                        </Box>
                        <img src={donateClothing} height={'675px'} width={'450px'} alt='donatClothing' />
                    </Container>
                    <div className='parent-housing-society' style={{ height: '650px' }} ref={(ref) => (sectionRefs.current[2] = ref)}>
                        <div className="housing-text-center" style={{ width: '1120px' }}>
                            <span className="main-text">Housing
                                <span className="sub-text">  Society : Organize collection drives!</span>
                            </span>
                        </div>
                        <div className='housing-society-content'>

                            <Box sx={{ height: '100%', width: '50%' }} >
                                <div className='involve-text-center' style={{ color: '#FFFFFF' }}>
                                    <div className='horizontal-line' style={{ marginBottom: '50px' }} />
                                    Become a part of our <span className='main-text'>mission</span> today.
                                </div>
                                <div className='involve-desc' style={{ maxWidth: '558px', textAlign: 'start', color: '#FFFFFF', marginTop: '25px' }}>
                                    Host a collection drive in your housing society. We'll provide the boxes and posters, and help you set everything up. Just choose a day for the collection, and we'll give you message templates to inform your residents through WhatsApp and posters. Sign up, and our team will contact you to arrange the details. It's a simple way to help out and bring your community together.
                                </div>
                                <Button
                                    size={isMobile ? 'medium' : 'large'}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: primaryColor,
                                        ':hover': {
                                            backgroundColor: primaryColor,
                                        },
                                        height: isMobile ? '45px' : '55px',
                                        width: isMobile ? '130px' : '165px',
                                        marginTop: '25px'
                                    }}
                                    onClick={() => dispatch(handleHousingSocietyModal(true))}
                                >
                                    Donate Now
                                </Button>
                            </Box>
                            <Box className='housingSociety-bg' />
                        </div>
                    </div>
                    <div className="involve-text-center" style={{ width: '100%', textAlign: 'center', margin: '50px' }} ref={(ref) => (sectionRefs.current[3] = ref)}>
                        <span className="sub-text" style={{ color: '#000000' }}>Become</span>
                        <span className="main-text"> Change Champions! </span>
                    </div>
                    <div className='horizontal-line' style={{ marginTop: 0 }} />
                    <div className='involve-desc' style={{ width: '871px', marginTop: '25px' }}>
                        Become a Change Champion with CYC and take the lead in your area or zone. By collaborating with our team, you'll organize collection drives, engage local communities, and become a key figure in promoting our cause. We'll support you with the necessary tools and guidance to mobilize your community effectively. This role is not just about leading; it's about making a tangible impact and inspiring others to join in. Sign up to be the change in your community and help us extend our reach.
                    </div>
                    <Button
                        size={isMobile ? 'medium' : 'large'}
                        variant="contained"
                        sx={{
                            backgroundColor: primaryColor,
                            ':hover': {
                                backgroundColor: primaryColor,
                            },
                            height: isMobile ? '45px' : '55px',
                            width: isMobile ? '130px' : '165px',
                            margin: '50px',
                        }}
                        onClick={() => dispatch(handleJoinUsModal(true))}
                    >
                        Join Now
                    </Button>
                    <div className='parent-housing-society' style={{ marginTop: '1%' }} ref={(ref) => (sectionRefs.current[4] = ref)}>
                        <div className="housing-text-center">
                            <span className="sub-text">  Corporates - Make it part of <span className="main-text">CSR!</span></span>

                        </div>
                        <div className='housing-society-content'>
                            <Box sx={{ height: '100%', width: '489px' }} >
                                <div className='involve-text-center' style={{ color: '#FFFFFF' }}>
                                    <div className='horizontal-line' style={{ marginBottom: '50px' }} />
                                    {/* Become a part of our <span className='main-text'>mission</span> today. */}
                                </div>
                                <div className='involve-desc' style={{ maxWidth: '558px', textAlign: 'start', color: '#FFFFFF', marginTop: '25px' }}>
                                    Corporate involvement is vital to expanding our reach and enhancing our impact. By partnering with CYC, your company can serve as a collection point, aiding in the gathering and distribution of clothing donations. Beyond offering space, there's an opportunity to engage your workforce in this noble cause, fostering a culture of community support and giving within your organization.
                                    <br /><br />
                                    But it doesn't end there. Your company can also provide invaluable support through sponsorships, marketing assistance, and even rewards for active participants. These contributions not only aid in the logistical aspects of our initiative but also amplify our message, encouraging more widespread participation.
                                    <br /><br />
                                    Moreover, your involvement with CYC offers a tangible way to enhance your Corporate Social Responsibility (CSR) profile. It demonstrates a commitment to sustainability and community welfare, resonating with employees, clients, and the broader public.

                                </div>
                                <Button
                                    size={isMobile ? 'medium' : 'large'}
                                    variant="contained"
                                    onClick={() => dispatch(handleCorporateParterModal(true))}
                                    sx={{
                                        backgroundColor: primaryColor,
                                        ':hover': {
                                            backgroundColor: primaryColor,
                                        },
                                        height: isMobile ? '45px' : '55px',
                                        width: isMobile ? '130px' : '165px',
                                        marginTop: '25px'
                                    }}
                                >
                                    Sign Up
                                </Button>
                            </Box>
                            <img src={corporateBanner} height={'380px'} width={'570px'} alt='corporateBanner' />
                        </div>
                    </div>
                    <Container sx={{ display: 'flex', height: '650px', margin: '50px' }} ref={(ref) => (sectionRefs.current[5] = ref)}>
                        <img src={NgoBanner} height={'639px'} width={'540px'} alt='NgoBanner' />

                        <Box sx={{ marginLeft: '30px', width: '950px' }}>
                            <div className="housing-text-center" style={{ width: '100%' }}>
                                <span className="main-text">NGOs
                                    <span className="sub-text" style={{ color: '#000000' }}>- Partner with us </span>
                                </span>
                            </div>
                            <div className='horizontal-line' />

                            <br /><br /><br />
                            <div className='involve-desc' style={{ width: '570px', textAlign: 'start' }}>
                                At CYC, we are eager to broaden our network of NGO partners across India. We are looking for organizations that not only distribute clothing to those in need but are also involved in recycling or repurposing clothes. Our aim is to ensure that every donated item is utilized to its fullest potential, whether it's providing warmth and dignity to someone or being transformed into a new product.
                                <br /><br />
                                As an NGO partner, you'll play a crucial role in the lifecycle of donated clothes. Your expertise in distribution, repurposing, and recycling will help maximize the impact of each donation. We are committed to working closely with our NGO partners to align our efforts, ensuring that the process is efficient, respectful, and beneficial for all parties involved.
                                <br /><br />
                                We welcome NGOs that accept various types of clothing items, as our goal is to cater to a wide range of needs and promote sustainability in every aspect of our initiative. By partnering with CYC, you'll be joining a network that is dedicated to making a tangible difference in communities and the environment.
                            </div><br /><br />
                            <Button
                                size={isMobile ? 'medium' : 'large'}
                                variant="contained"
                                sx={{
                                    backgroundColor: primaryColor,
                                    ':hover': {
                                        backgroundColor: primaryColor,
                                    },
                                    height: isMobile ? '45px' : '55px',
                                    width: isMobile ? '130px' : '236px',
                                    // marginTop: '25px'
                                }}
                                onClick={() => dispatch(handleNgoPartnerModal(true))}
                            >
                                PARTNER WITH US
                            </Button>
                        </Box>
                    </Container>
                    {/* <br /> */}
                    {/* <br /> */}
                </>
            }
            <div className='involve-section-3'>
                <div className="involve-text-center" style={{ width: '100%', textAlign: 'center' }}>
                    <span className="main-text" style={{ fontSize: !isMobile ?'54px' : '24px' }}> Join us</span>
                </div>
                {/* <br /> */}
                <div className='about-horizontal-line' style={{ width: isMobile ? '61px' : '100px', marginTop: isMobile ? '0' : '25px' }}/>
                <div className='involve-desc' style={{ width: isMobile ? '100%' : '871px', marginTop: '25px' }}>
                    Together, we can expand our reach, enhance our impact, and build a more sustainable and compassionate future. Join us in our mission to transform the way clothing donations are made and utilized across India.
                </div>
                <br /><br />
                <Button
                    size={isMobile ? 'medium' : 'large'}
                    variant="contained"
                    sx={{
                        backgroundColor: primaryColor,
                        ':hover': {
                            backgroundColor: primaryColor,
                        },
                        height: isMobile ? '45px' : '55px',
                        width: isMobile ? '130px' : '191px',
                        // marginTop: '25px'
                    }}
                    onClick={() => dispatch(handleJoinUsModal(true))}
                >
                    JOIN NOW
                </Button>
            </div>
        </div>
    );
}

export default BlackDiv;
