import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { primaryColor } from '../../Styles/styles';
import ImageIcon from '@mui/icons-material/Image';
import LogoSVG from '../../assets/svg/Clean Your Closet(white).png';
import { useLocation, useNavigate } from 'react-router-dom';

const  SideBar = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const menuItems = [
        { text: 'Gallery', path: '/adminHome' },
        { text: 'Partner', path: '/adminPartner' }
    ];

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '25vh', flexDirection: 'row' }}>
      <Box bgcolor={'#333333'} sx={{ width: '25vh', height: '100vh', textAlign: 'center' }} role="presentation" >
        <img src={LogoSVG} alt="Logo" style={{ maxWidth: '100%', height: 'auto', marginTop: '5vh' }} />
        <List sx={{ marginTop: '5vh' }}>
          {menuItems.map((item, index) => (
            <ListItem onClick={()=>navigate(item.path)} sx={{ backgroundColor: location.pathname === item.path ? primaryColor : '#333333' }} key={index} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ImageIcon htmlColor='#ffffff' />
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ color: '#ffffff' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default SideBar